import { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { CustomButton } from 'components/common';
import { InputField } from 'components/common/InputField';
import CoreLayout from 'components/layouts/CoreLayout';
import UserContext from 'context/user/UserContext';
import { BasicDatePicker } from 'components/common/BasicDatePicker';
import { deepCopy } from 'helpers';

export const MyStory = () => {
  const { updateUser, getUser, userData } = useContext(UserContext);

  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    pronunciation: '',
    country: '',
    city: '',
    phoneNumber: '',
    email: '',
    address: '',
    languages: [
      {
        languageName: '',
        level: '',
      },
      {
        languageName: '',
        level: '',
      },
    ],
    education: [
      {
        educationEstablishment: '',
        graduationLevel: '',
        faculty: 'CS',
        graduationYear: '2016',
      },
      {
        educationEstablishment: '',
        graduationLevel: '',
        faculty: 'CS',
        graduationYear: '2016',
      },
    ],
  });

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setPersonalInfo(userData);
  }, [userData]);

  const discardChanges = () => setPersonalInfo(deepCopy(userData));

  const handleChangeLanguage = (index, type, value) => {
    const newLanguages = personalInfo?.languages;
    newLanguages[index][type] = value;
    setPersonalInfo({ ...personalInfo, languages: newLanguages });
  };

  const handleChangeEducation = (index, type, value) => {
    const newEducation = personalInfo?.education;
    newEducation[index][type] = value;
    setPersonalInfo({ ...personalInfo, education: newEducation });
  };

  const onSubmit = () => {
    const {
      _id,
      __v,
      email,
      cognitoId,
      createdAt,
      updatedAt,
      languages,
      ...newPersonalInfo
    } = personalInfo;
    const newLanguages = languages
      .map((language) => {
        const { _id, ...newLanguage } = language;
        return newLanguage;
      })
      .filter((language) => {
        return !Object.values(language).every((value) => value === '');
      });
    const newEducation = personalInfo?.education
      .map((education) => {
        const { _id, ...newEducation } = education;
        return newEducation;
      })
      .filter((education) => {
        return !Object.values(education).every((value) => value === '');
      });

    // if (email !== userData?.email) {
    //   updateEmail(email);
    // }
    updateUser({
      ...newPersonalInfo,
      languages: newLanguages,
      education: newEducation,
    });
  };

  return (
    <CoreLayout pageTitle="My Story">
      <Typography
        sx={{
          fontSize: '20px',
          lineHeight: '24px',
          color: 'rgba(107, 133, 7, 1)',
          fontWeight: '700',
          mb: '12px',
        }}
      >
        Personal Info
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ marginRight: '60px' }}>
          <img
            src="/profile-pic.png"
            alt="profile-pic"
            style={{
              width: '220px',
              height: '220px',
              borderRadius: '8px',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            columnGap: '20px',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              columnGap: '4px',
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                color: '#494E57',
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
              }}
            >
              First Name:
            </Typography>
            <InputField
              style={{ width: '100%' }}
              value={personalInfo?.firstName}
              fullWidth={true}
              onChange={(_, value) =>
                setPersonalInfo({ ...personalInfo, firstName: value })
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              columnGap: '4px',
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                color: '#494E57',
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
              }}
            >
              Last Name:
            </Typography>
            <InputField
              style={{ width: '100%' }}
              value={personalInfo?.lastName}
              fullWidth={true}
              onChange={(_, value) =>
                setPersonalInfo({ ...personalInfo, lastName: value })
              }
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px' }}>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                columnGap: '4px',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: '#494E57',
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: '4px',
                }}
              >
                Date of Birth:
              </Typography>
              <BasicDatePicker
                date={personalInfo?.dateOfBirth}
                onChange={(_, value) =>
                  setPersonalInfo({ ...personalInfo, dateOfBirth: value })
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                columnGap: '4px',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: '#494E57',
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: '4px',
                }}
              >
                Pronunciation:
              </Typography>
              <InputField
                style={{ width: '100%' }}
                value={personalInfo?.pronunciation}
                fullWidth={true}
                // clearable
                onChange={(_, value) =>
                  setPersonalInfo({ ...personalInfo, pronunciation: value })
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Typography
        sx={{
          fontSize: '20px',
          lineHeight: '24px',
          color: 'rgba(107, 133, 7, 1)',
          fontWeight: '700',
          mt: '40px',
          mb: '12px',
        }}
      >
        Contact Info
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          flexGrow: 1,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px' }}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              columnGap: '4px',
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                color: '#494E57',
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
              }}
            >
              Country:
            </Typography>
            <InputField
              style={{ width: '100%' }}
              value={personalInfo?.country}
              fullWidth={true}
              onChange={(_, value) =>
                setPersonalInfo({ ...personalInfo, country: value })
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              columnGap: '4px',
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                color: '#494E57',
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
              }}
            >
              City:
            </Typography>
            <InputField
              style={{ width: '100%' }}
              value={personalInfo?.city}
              fullWidth={true}
              onChange={(_, value) =>
                setPersonalInfo({ ...personalInfo, city: value })
              }
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '80px' }}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              columnGap: '4px',
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                color: '#494E57',
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
              }}
            >
              Contact phone no:
            </Typography>
            <InputField
              style={{ width: '100%' }}
              value={personalInfo?.phoneNumber}
              fullWidth={true}
              onChange={(_, value) =>
                setPersonalInfo({ ...personalInfo, phoneNumber: value })
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              columnGap: '4px',
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                color: '#494E57',
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
              }}
            >
              Contact email:
            </Typography>
            <InputField
              style={{ width: '100%' }}
              value={personalInfo?.email}
              fullWidth={true}
              disabled={true}
              onChange={(_, value) =>
                setPersonalInfo({ ...personalInfo, email: value })
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            columnGap: '4px',
            position: 'relative',
          }}
        >
          <Typography
            sx={{
              color: '#494E57',
              fontSize: '14px',
              lineHeight: '20px',
              mb: '4px',
            }}
          >
            Address:
          </Typography>
          <InputField
            style={{ width: '100%' }}
            value={personalInfo?.address}
            fullWidth={true}
            onChange={(_, value) =>
              setPersonalInfo({ ...personalInfo, address: value })
            }
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '6px 0px',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '24px',
            color: 'rgba(107, 133, 7, 1)',
            fontWeight: '700',
            mt: '40px',
            mb: '12px',
          }}
        >
          Languages
        </Typography>
        <CustomButton
          variant="text"
          label="Add More Languages"
          imgSrc="/plus-icon.svg"
          onClick={() => {
            setPersonalInfo({
              ...personalInfo,
              languages: [
                ...personalInfo?.languages,
                { languageName: '', level: '' },
              ],
            });
          }}
          style={{
            marginTop: 'auto',
            marginBottom: '4px',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          flexGrow: 1,
        }}
      >
        {personalInfo?.languages.map((language, index) => (
          <Box
            key={`${language}-${index}`}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              p: '12px',
              backgroundColor: '#FAFBFC',
              borderRadius: '12px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                columnGap: '4px',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: '#494E57',
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: '4px',
                }}
              >
                Language:
              </Typography>
              <InputField
                style={{ width: '100%' }}
                value={language.languageName}
                fullWidth={true}
                onChange={(_, value) => {
                  handleChangeLanguage(index, 'languageName', value);
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                columnGap: '4px',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: '#494E57',
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: '4px',
                }}
              >
                Level:
              </Typography>
              <InputField
                style={{ width: '100%' }}
                value={language.level}
                fullWidth={true}
                onChange={(_, value) => {
                  handleChangeLanguage(index, 'level', value);
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '6px 0px',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '24px',
            color: 'rgba(107, 133, 7, 1)',
            fontWeight: '700',
            mt: '40px',
            mb: '12px',
          }}
        >
          Education Story
        </Typography>
        <CustomButton
          variant="text"
          label="Add More Education"
          imgSrc="/plus-icon.svg"
          onClick={() => {
            setPersonalInfo({
              ...personalInfo,
              education: [
                ...personalInfo?.education,
                { educationEstablishment: '', graduationLevel: '' },
              ],
            });
          }}
          style={{
            marginBottom: '4px',
            marginTop: 'auto',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          flexGrow: 1,
        }}
      >
        {personalInfo?.education.map((education, index) => (
          <Box
            key={`${education}-${index}`}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              p: '12px',
              backgroundColor: '#FAFBFC',
              borderRadius: '12px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '75%',
                flexDirection: 'column',
                columnGap: '4px',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: '#494E57',
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: '4px',
                }}
              >
                Educational establishment:
              </Typography>
              <InputField
                style={{ width: '100%' }}
                value={education.educationEstablishment}
                fullWidth={true}
                onChange={(_, value) =>
                  handleChangeEducation(index, 'educationEstablishment', value)
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '25%',
                flexDirection: 'column',
                columnGap: '4px',
                position: 'relative',
              }}
            >
              <Typography
                sx={{
                  color: '#494E57',
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: '4px',
                }}
              >
                Graduation level:
              </Typography>
              <InputField
                style={{ width: '100%' }}
                value={education.graduationLevel}
                fullWidth={true}
                onChange={(_, value) =>
                  handleChangeEducation(index, 'graduationLevel', value)
                }
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          mt: '40px',
          justifyContent: 'flex-end',
        }}
      >
        <CustomButton
          label="Discard all Changes"
          variant="outlined"
          onClick={discardChanges}
          style={{ marginRight: '12px' }}
        />
        <CustomButton
          label="Save Changes"
          autoFocus
          style={{ width: '180px' }}
          onClick={onSubmit}
          variant="filled"
        />
      </Box>
    </CoreLayout>
  );
};
