import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { InputField } from './InputField';
import { DropdownComponent } from './DropdownComponent';
import { CustomButton } from './CustomButton';
import { TrashIcon } from './icons/TrashIcon';

export const SaveCVDialog = ({
  foldersList = [],
  data,
  onChange = () => {},
  showSubscriptionPlan = false,
  showLockFromUpdates = false,
  setIsFolderFormDirty = () => {},
}) => {
  const [showCreateFolderField, setShowCreateFolderField] = useState(false);

  const dropdownOptions = useMemo(
    () => [
      { id: '', label: 'Please select' },
      ...foldersList.map((folder) => {
        return {
          id: folder?.['_id'],
          label: folder.folderName || '-',
        };
      }),
    ],
    [foldersList]
  );

  const foldersMap = useMemo(
    () =>
      dropdownOptions.map((k) => {
        return { [k.id]: k.label };
      }),
    [dropdownOptions]
  );

  return (
    <>
      <Box sx={{ mb: '20px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            mb: '4px',
            color: '#494E57',
          }}
        >
          Document name:
        </Typography>
        <InputField
          name="documentName"
          placeholder="Document 1"
          value={data?.documentName || ''}
          fullWidth={true}
          clearable
          onChange={(_, value) => {
            onChange('documentName', value);
          }}
        />
      </Box>
      <Box sx={{ mb: '20px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            mb: '4px',
            color: '#494E57',
          }}
        >
          Position:
        </Typography>
        <InputField
          name="position"
          placeholder="Engineer"
          value={data?.position || ''}
          fullWidth={true}
          clearable
          onChange={(_, value) => {
            onChange('position', value);
          }}
        />
      </Box>
      <Box sx={{ mb: '20px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            mb: '4px',
            color: '#494E57',
          }}
        >
          Company name:
        </Typography>
        <InputField
          name="companyName"
          placeholder="Company"
          value={data?.companyName || ''}
          fullWidth={true}
          clearable
          onChange={(_, value) => {
            onChange('companyName', value);
          }}
        />
      </Box>
      <Box sx={{ mb: '20px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            mb: '4px',
            color: '#494E57',
          }}
        >
          Tags:
        </Typography>
        <InputField
          name="tags"
          placeholder="Start typing to add tags"
          value={''}
          fullWidth={true}
          clearable
          onChange={(_, value) => {}}
        />
      </Box>
      <Box sx={{ mb: '20px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            mb: '4px',
            color: '#494E57',
          }}
        >
          Note:
        </Typography>
        <InputField
          name="note"
          onChange={(_, value) => {
            onChange('documentNote', value);
          }}
          placeholder="Note"
          minRows={3}
          maxRows={5}
          value={data?.documentNote || ''}
          fullWidth={true}
          multiline={true}
          clearable
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            mb: '4px',
            color: '#494E57',
          }}
        >
          Save to Folder:
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexGrow: '1' }}>
            <DropdownComponent
              defaultValue={{
                id: data?.folderId || '',
                label: foldersMap[data?.folderId] || 'Please select',
              }}
              style={{
                width: '250px',
                maxWidth: '100%',
                mb: 2,
                mr: '20px',
                mt: 0,
                display: 'flex',
                flexGrow: '1',
              }}
              options={dropdownOptions}
              onChange={(_, value) => {
                onChange('folderId', value?.id);
              }}
            />
            <CustomButton
              label="Create New Folder"
              variant="text"
              onClick={() => setShowCreateFolderField(true)}
              sx={{ width: 'max-content', ml: 'auto' }}
            />
          </Box>
        </Box>
      </Box>

      {showLockFromUpdates ? (
        <FormControlLabel
          label="Lock from updates"
          sx={{ color: '#494E57', fontSize: '14px', lineHeight: '20px' }}
          control={<Checkbox checked={false} onChange={() => {}} />}
        />
      ) : null}

      {showCreateFolderField ? (
        <Box sx={{ mt: '20px', height: '66px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: '700',
              mb: '4px',
              color: '#494E57',
            }}
          >
            New Folder:
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <InputField
              style={{ flexGrow: 1 }}
              onChange={(_, value) => {
                onChange('folderName', value);
                setIsFolderFormDirty(true);
              }}
              placeholder="New Folder Name"
              value={data?.folderName || ''}
              fullWidth={true}
              multiline={true}
              clearable
            />
            <IconButton
              sx={{
                width: '36px',
                height: '36px',
                p: 0,
                ml: '8px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#F0F2F5',
                  borderRadius: '8px',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TrashIcon
                  sx={{ width: '20px', height: '20px' }}
                  onClick={() => {
                    setShowCreateFolderField(false);
                    setIsFolderFormDirty(false);
                    onChange('folderName', '');
                  }}
                />
              </Box>
            </IconButton>
          </Box>
        </Box>
      ) : null}

      {showSubscriptionPlan ? (
        <Typography>
          Documents generated: {4} of {10} available according to your{' '}
          <CustomButton
            label="Subscription Plan"
            variant="text"
            sx={{ width: '103px', mt: '40px' }}
          />
        </Typography>
      ) : null}
    </>
  );
};
