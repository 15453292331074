import React, { useState } from 'react';
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { formatDateFromMiliseconds } from 'helpers';

export const BasicDatePicker = ({ date = null, onChange = () => {} }) => {
  const [selectedDate, setSelectedDate] = useState(
    moment(formatDateFromMiliseconds(new Date(date).getTime())).format(
      'DD/MM/YYYY'
    )
  );

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue ? moment(newValue).toISOString() : null);
    onChange(null, moment(newValue).toISOString());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        value={selectedDate ? moment(selectedDate) : null}
        format="DD/MM/YYYY"
        onChange={(newValue) => {
          handleDateChange(newValue);
        }}
        slotProps={{
          textField: {
            size: 'small',
            variant: 'outlined',
            sx: {
              '.MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D7DAE0',
              },
              '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                color: 'black',
              },
              '& .MuiOutlinedInput-root': {
                maxHeight: '36px',
                paddingX: '16px',
                borderRadius: '8px',
              },
              '& .MuiOutlinedInput-input': {
                zIndex: 1,
                fontSize: '14px',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
