// Variables for the Reducer

export const IS_LOADING = 'IS_LOADING';

export const SET_USER_INFO = 'SET_USER_INFO';

export const SET_USER_GROUP = 'SET_USER_GROUP';

export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
