import { useReducer } from 'react';
import {
  getDocumentById,
  deleteDocumentById,
  getFoldersData,
  deleteFolderById,
  createNewFolder,
  updateDocumentById,
  createNewDocument,
  updateFolderById,
  getDocuments,
  getDraftDocuments,
  getFavouriteDocuments,
  toggleFavouriteDocument,
  getNotDraftDocumentsGroupedByFolder,
} from '../../api/document';
import DocumentContext from './DocumentContext';
import documentReducer from './DocumentReducer';
import {
  GET_DOCUMENT,
  IS_LOADING,
  CREATE_DOCUMENT,
  GET_FOLDERS,
  CREATE_FOLDER,
  DELETE_FOLDER,
  DELETE_DOCUMENT,
  UPDATE_FOLDER,
  UPDATE_DOCUMENT,
  GET_ALL_DOCUMENTS,
  GET_ALL_DRAFT_DOCUMENTS,
  GET_ALL_FAVOURITES_DOCUMENTS,
  TOGGLE_FAVOURITE_DOCUMENT,
  GET_NON_DRAFT_DOCUMENTS_IN_FOLDERS,
} from './types';
import { deepCopy } from 'helpers';

export const initialState = {
  document: null,
  allDocuments: [],
  draftDocuments: [],
  favouritesDocuments: [],
  selectedFolder: null,
  folders: [],
  isLoading: false,
  nonDraftFolders: [],
};

const DocumentState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the templateReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(documentReducer, initialState);

  const getDocumentData = (documentId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getDocumentById(documentId)
      .then((result) => {
        // dispatch action to the reducer and update the state accordingly
        dispatch({
          type: GET_DOCUMENT,
          payload: result,
        });
        return result;
      })
      .finally((result) => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
        return result;
      });
  };

  const getAllDocuments = () => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getDocuments()
      .then((res) => {
        dispatch({
          type: GET_ALL_DOCUMENTS,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const getAllDraftDocuments = () => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getDraftDocuments()
      .then((res) => {
        dispatch({
          type: GET_ALL_DRAFT_DOCUMENTS,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const getAllFavouritesDocuments = () => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getFavouriteDocuments()
      .then((res) => {
        dispatch({
          type: GET_ALL_FAVOURITES_DOCUMENTS,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const createDocument = async (documentData) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });

    await createNewDocument(documentData)
      .then((res) => {
        dispatch({
          type: CREATE_DOCUMENT,
          payload: res,
        });
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const updateDocument = async (documentId, documentData) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    await updateDocumentById(documentId, documentData)
      .then((res) => {
        dispatch({
          type: UPDATE_DOCUMENT,
          payload: res,
        });
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const updateFolder = async (folderId, folderName, callback = () => {}) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    await updateFolderById(folderId, folderName)
      .then((res) => {
        dispatch({
          type: UPDATE_FOLDER,
          payload: res,
        });
        callback();
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const createFolder = async (folderName) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    return await createNewFolder(folderName)
      .then((res) => {
        dispatch({
          type: CREATE_FOLDER,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const createFolderAndSaveDocument = async (
    folderName,
    documentData,
    mode
  ) => {
    await createFolder(folderName)
      .then((res) => {
        const newFolder = res?.find(
          (folder) => folder.folderName === folderName
        );

        if (newFolder && newFolder?._id) {
          const newDocumentData = deepCopy(documentData);
          newDocumentData.folderId = newFolder._id;
          if (mode === 'edit') {
            updateDocument(documentData._id, newDocumentData);
          } else {
            createDocument(newDocumentData);
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const deleteDocument = (documentId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    deleteDocumentById(documentId)
      .then((res) => {
        dispatch({
          type: DELETE_DOCUMENT,
          payload: res,
        });
        getFolders();
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const getFolders = () => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getFoldersData()
      .then((res) => {
        dispatch({
          type: GET_FOLDERS,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const getNonDraftDocumentsInFolders = () => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    getNotDraftDocumentsGroupedByFolder()
      .then((res) => {
        dispatch({
          type: GET_NON_DRAFT_DOCUMENTS_IN_FOLDERS,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const toggleFavourite = (documentId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    toggleFavouriteDocument(documentId)
      .then((res) => {
        dispatch({
          type: TOGGLE_FAVOURITE_DOCUMENT,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  const deleteFolder = (folderId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    deleteFolderById(folderId)
      .then((res) => {
        dispatch({
          type: DELETE_FOLDER,
          payload: res,
        });
      })
      .finally(() => {
        dispatch({
          type: IS_LOADING,
          payload: false,
        });
      });
  };

  return (
    <DocumentContext.Provider
      value={{
        state: state || {},
        getDocumentData,
        deleteDocument,
        createDocument,
        updateDocument,
        getFolders,
        createFolder,
        deleteFolder,
        updateFolder,
        getAllDocuments,
        getAllDraftDocuments,
        getAllFavouritesDocuments,
        toggleFavourite,
        createFolderAndSaveDocument,
        getNonDraftDocumentsInFolders,
      }}
    >
      {props.children}
    </DocumentContext.Provider>
  );
};

export default DocumentState;
