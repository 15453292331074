import React from 'react';
import { Box, Alert, IconButton, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CustomAlert = ({
  message,
  isOpen,
  closable = true,
  onClose = () => {},
  severity = 'error',
  variant = 'filled',
  backgroundColor = '',
}) => {
  return (
    <Box
      sx={{
        width: '90%',
        marginTop: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Collapse in={isOpen}>
        <Alert
          {...(closable && {
            action: (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            ),
          })}
          sx={{
            mb: 2,
            ...(backgroundColor ? { backgroundColor: backgroundColor } : ''),
            ' .MuiAlert-icon': { height: '12px', width: '12px' },
            ' .MuiAlert-message': {
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              fontSize: '13px',
            },
            ' .MuiSvgIcon-root': { height: '14px', width: '14px' },
          }}
          variant={variant}
          severity={severity}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};
