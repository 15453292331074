import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomButton } from './CustomButton';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '32px 40px 40px 40px',
    borderRadius: '12px',
    minHeight: '480px',
  },
  '& .MuiDialog-paperFullWidth': {
    width: '100vw',
    maxWidth: '400px',
    height: 'max-content',
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
}));

export const ConfirmationDialog = ({
  open,
  onClose,
  title,
  children,
  descriptionLines,
  primaryBtnLabel,
  primaryBtnOnClick,
  illustrationSrc = '',
  secondaryBtnLabel = '',
  secondaryBtnOnClick = () => {},
}) => {
  return (
    <Box>
      <CustomDialog onClose={onClose} open={open} fullWidth={true}>
        <DialogContent>
          {illustrationSrc ? (
            <img
              src={illustrationSrc}
              alt="illustration"
              style={{ display: 'block', margin: 'auto', mb: '8px' }}
            />
          ) : null}
          {title ? (
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#494E57',
                textAlign: 'center',
                mb: '8px',
              }}
            >
              {title}
            </Typography>
          ) : null}
          {descriptionLines?.length ? (
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                color: '#494E57',
                textAlign: 'center',
                mb: '60px',
              }}
            >
              {descriptionLines?.map((line, index) => (
                <span key={index}>
                  {line}
                  <br />{' '}
                </span>
              ))}
            </Typography>
          ) : null}
          {children}
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {secondaryBtnLabel ? (
            <CustomButton
              label={secondaryBtnLabel}
              variant="outlined"
              onClick={secondaryBtnOnClick}
              style={{ width: '120px', marginRight: '8px' }}
            />
          ) : null}
          <CustomButton
            label={primaryBtnLabel}
            variant="filled"
            style={{ width: !secondaryBtnLabel ? '100%' : '192px' }}
            onClick={primaryBtnOnClick}
          />
        </DialogActions>
      </CustomDialog>
    </Box>
  );
};
