import { useState, useEffect, useMemo } from 'react';

const useDeviceType = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Memoized device type calculations
  const { isMobile, isTablet, isDesktop } = useMemo(() => {
    const isMobile = windowWidth <= 768;
    const isTablet = windowWidth > 768 && windowWidth <= 1024;
    const isDesktop = windowWidth > 1024;

    return { isMobile, isTablet, isDesktop };
  }, [windowWidth]);

  return { isMobile, isTablet, isDesktop };
};

export default useDeviceType;
