import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUserData = async () => {
  const response = await axios.get(`${BASE_URL}/user/get`);
  return response?.data || [];
};

export const updateUserData = async (data) => {
  const response = await axios.put(`${BASE_URL}/user/update`, data);
  return response?.data || [];
};

export const updateUserEmail = async (email) => {
  const response = await axios.put(`${BASE_URL}/user/changeEmail`, { email });
  return response?.data || [];
};
