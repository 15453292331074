export const isSimpleSection = (section) => {
  return [
    'Multiple Lines Single Column',
    'Multiple Lines Dual Column',
    'Single Line Single Column',
  ].includes(section?.sectionStyle);
};

export const handleFolderFilter = (
  folderName,
  filters,
  setFilters,
  foldersList,
  foldersState,
  setFilteredFoldersData
) => {
  setFilters({ ...filters, folder: folderName });
  if (folderName === 'All Folders') {
    setFilteredFoldersData(foldersList);
    return foldersList;
  }

  const filteredFolders = foldersState?.filter(
    (f) => f.folderName === folderName
  );

  setFilteredFoldersData(filteredFolders);
  return filteredFolders;
};

export const handleKeywordFilter = (
  keyword,
  data,
  filters,
  setFilters,
  setFilteredFoldersData
) => {
  if (!keyword) {
    setFilters({ ...filters, keyword: '' });
    setFilteredFoldersData(data);
    return;
  }

  setFilters({ ...filters, keyword });

  const filteredFolders = data.map((f) => {
    let documents = f.documents?.filter((d) =>
      d.documentName.toLowerCase().includes(keyword.toLowerCase())
    );
    return { ...f, documents };
  });
  setFilteredFoldersData(filteredFolders);
};

export const allocateEmptyLineInSection = (section) => {
  const lineLocations = section?.allocatedLines?.map(
    (line) => line?.lineLocation
  );
  const maxLineLocation = lineLocations?.length
    ? Math.max(...lineLocations)
    : 0;
  const newSectionData = deepCopy(section);

  if (!newSectionData?.allocatedLines) {
    newSectionData.allocatedLines = [];
  }

  newSectionData?.allocatedLines.push({
    lineLocation: maxLineLocation + 1,
    lineText: '',
  });

  return newSectionData;
};

export const initialiseSection = (section) => {
  let { allocatedLines, ...sectionData } = deepCopy(section);

  // Add a line if no allocated lines and the section style requires it
  const needsLines = !allocatedLines?.length && isSimpleSection(section);

  if (needsLines) {
    return allocateEmptyLineInSection(section);
  }

  return { ...sectionData, allocatedLines };
};

export const getUserGroup = (userData) => {
  if (!userData) {
    return 'notAuthorised';
  }
  return userData['cognito:groups'] && userData['cognito:groups'].length
    ? userData['cognito:groups'][0]
    : 'noUserGroup';
};

export const formatDateFromMiliseconds = (miliseconds) => {
  const date = new Date(parseInt(miliseconds, 10));
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day < 10 ? '0' : ''}${day}/${
    month < 10 ? '0' : ''
  }${month}/${date.getFullYear()}`;
};

export const formatTimeFromMiliseconds = (miliseconds) => {
  const date = new Date(parseInt(miliseconds, 10));
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const deepCopy = (element) => JSON.parse(JSON.stringify(element));

export const setLocalStorage = (name, value) => {
  const valueToSave = JSON.stringify(value);

  localStorage.setItem(name, valueToSave);
};

export const loadLocalStorage = (name) => {
  const item = localStorage.getItem(name);
  if (!item) return null;

  return JSON.parse(item);
};

export const removeFromLocalStorage = (name) => {
  if (loadLocalStorage(name)) localStorage.removeItem(name);
};

export const removeIdKeys = (obj) => {
  // Check if the current object is an array
  if (Array.isArray(obj)) {
    obj.forEach((item) => removeIdKeys(item)); // Recur for each item in the array
  } else if (typeof obj === 'object' && obj !== null) {
    // Loop through the keys of the object
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // If the key is "_id", delete it
        if (key === '_id') {
          delete obj[key];
        } else if (typeof obj[key] === 'object') {
          // If the value is an object, recurse into it
          removeIdKeys(obj[key]);
        }
      }
    }
  }
};
