import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size="4rem" />
    </Box>
  );
};
