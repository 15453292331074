import { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';
import DocumentContext from 'context/document/DocumentContext';
import { formatDateFromMiliseconds } from 'helpers';
import { CustomButton } from './CustomButton';
import { ConfirmationDialog } from './ConfirmationDialog';
import { InputField } from './InputField';

export const DocumentsList = ({
  foldersList,
  activeDocument,
  shouldShowDivider = false,
  pageName = '',
  showDotsMenu = false,
  setActiveDocument,
  shouldRedirect = true,
  onFolderUpdate = () => {},
}) => {
  const { updateFolder, deleteFolder } = useContext(DocumentContext);
  const navigate = useNavigate();

  const [shouldShowRenameInput, setShouldShowRenameInput] = useState(false);
  const [selectedFolderData, setSelectedFolderData] = useState(null);
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [showConfirmationDeletionDialog, setShowConfirmationDeletionDialog] =
    useState(false);

  const [anchorElements, setAnchorElements] = useState([]);
  const [indexOfOpenMenu, setIndexOfOpenMenu] = useState(null);

  const handleOpenMenu = (event, index, folder) => {
    const newAnchorElements = anchorElements;
    setIndexOfOpenMenu(index);
    newAnchorElements[index] = event.currentTarget;
    setAnchorElements(newAnchorElements);
    setSelectedFolderData(folder);
  };

  const handleClose = (index) => {
    const newAnchorElements = anchorElements;
    newAnchorElements[index] = null;
    setAnchorElements(newAnchorElements);
    setIndexOfOpenMenu(null);
    setShouldShowRenameInput(false);
  };

  const handleRenameFolder = (index) => {
    handleClose(index);
    setShouldShowRenameInput(true);
  };

  const handleFolderCreation = async () => {
    setShouldShowRenameInput(false);
    await updateFolder(
      selectedFolderData['_id'],
      selectedFolderData.folderName,
      onFolderUpdate
    );
  };

  const handleDeleteFolder = () => {
    deleteFolder(selectedFolderData['_id']);
    setShowDeletionDialog(true);
  };

  const handleChoosingDocument = (document) => {
    if (shouldRedirect) {
      navigate(`/${pageName}/${document?._id}`);
    } else {
      if (activeDocument?._id === document?._id) {
        setActiveDocument(null);
      } else {
        setActiveDocument(document);
      }
    }
  };
  return (
    <Box className="documents-list">
      {foldersList?.map((folder, index) => (
        <Fragment key={folder?.['_id']}>
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {shouldShowRenameInput &&
              selectedFolderData?.['_id'] === folder['_id'] ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '4px',
                    position: 'relative',
                  }}
                >
                  <Box>
                    <InputField
                      style={{
                        width: '360px',
                        '.MuiOutlinedInput-root': { pl: 0 },
                      }}
                      value={selectedFolderData.folderName}
                      fullWidth={true}
                      clearable
                      onChange={(_, value) =>
                        setSelectedFolderData({
                          ...selectedFolderData,
                          folderName: value,
                        })
                      }
                    />
                  </Box>
                  <Box sx={{ mt: 'auto', display: 'flex', columnGap: '4px' }}>
                    <CustomButton
                      style={{
                        backgroundColor: '#F0F2F5',
                        minWidth: 0,
                        width: '36px',
                        height: '36px',
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'no-wrap',
                      }}
                      imgSrc="/close-icon.svg"
                      iconStyle={{
                        height: '20px',
                        width: '20px',
                        display: 'block',
                        margin: 'auto',
                      }}
                      onClick={() => handleClose(index)}
                    />
                    <CustomButton
                      style={{
                        backgroundColor: '#F2F7DF',
                        minWidth: 0,
                        width: '36px',
                        height: '36px',
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'no-wrap',
                      }}
                      imgSrc="/tick-icon.svg"
                      iconStyle={{
                        height: '20px',
                        width: '20px',
                        display: 'block',
                        margin: 'auto',
                      }}
                      onClick={handleFolderCreation}
                    />
                  </Box>
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: '20px',
                    lineHeight: '24px',
                    fontWeight: '700',
                    color: '#6B8507',
                    my: 'auto',
                  }}
                >
                  {folder.folderName}
                </Typography>
              )}
              {showDotsMenu ? (
                <>
                  <CustomButton
                    style={{
                      backgroundColor: 'transparent',
                      minWidth: 0,
                      width: '32px',
                      height: '32px',
                      padding: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'no-wrap',
                    }}
                    imgSrc="/dots-menu-icon.svg"
                    iconStyle={{
                      height: 'auto',
                    }}
                    onClick={(event) => handleOpenMenu(event, index, folder)}
                  />

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElements[index]}
                    open={indexOfOpenMenu === index}
                    onClose={() => handleClose(index)}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          // overflow: 'visible',
                          boxShadow: '0px 8px 28px 0px rgba(34, 42, 2, 0.12)',
                          borderRadius: '8px',
                          // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          ml: 3,
                          width: '220px',
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={handleRenameFolder}>
                      Rename Folder
                    </MenuItem>
                    <MenuItem
                      disabled={folder?.documents?.length > 0}
                      onClick={() => setShowConfirmationDeletionDialog(true)}
                    >
                      Delete Folder
                    </MenuItem>
                  </Menu>
                </>
              ) : null}
            </Box>

            {shouldShowDivider ? (
              <Divider
                sx={{
                  position: 'absolute',
                  width: '100%',
                  p: '1px 3px',
                  borderColor: '#D7DAE0',
                }}
              />
            ) : null}
          </Box>
          <Grid
            container
            spacing={'20px'}
            key={folder?.['_id']}
            sx={{
              mt: 0,
              justifyContent: 'flex-start',
              // backgroundColor: 'white',
              borderRadius: '8px',
              p: '12px',
              mb: '40px',
            }}
          >
            {folder.documents?.map((document) => (
              <Grid item key={document?.['_id']}>
                <Box
                  sx={{
                    height: '160px',
                    width: '118px',
                    backgroundImage: `url(${
                      document?.templateImageUrl || '/template1.png'
                    })`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '8px',
                    position: 'relative',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                    overflow: 'hidden',
                    ...(activeDocument?._id === document?._id && {
                      border: '2px solid #6B8507',
                    }),
                  }}
                  className={`${
                    activeDocument?._id === document?._id
                      ? 'imgCustom-active'
                      : 'imgCustom'
                  }`}
                  onClick={() => {
                    handleChoosingDocument(document);
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      height: '44px',
                      backgroundColor: '#E4F0B4',
                      zIndex: 3,
                      width: '100%',
                      borderBottomRadius: '8px',
                      padding: '4px 8px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#494E57',
                      }}
                    >
                      {document?.documentName}
                    </Typography>
                    {document?.updatedAt ? (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          lineHeight: '16px',
                          color: '#707682',
                        }}
                      >
                        {formatDateFromMiliseconds(
                          new Date(document?.updatedAt).getTime()
                        )}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Fragment>
      ))}

      <ConfirmationDialog
        open={showConfirmationDeletionDialog}
        title="You are about to delete this folder"
        descriptionLines={[
          'With this action you will lose all its data with no restore option.',
          'Are you sure?',
        ]}
        illustrationSrc="/question-illustration.svg"
        secondaryBtnLabel="Cancel"
        secondaryBtnOnClick={() => setShowConfirmationDeletionDialog(false)}
        primaryBtnLabel="Delete Folder"
        primaryBtnOnClick={() => {
          setShowConfirmationDeletionDialog(false);
          handleDeleteFolder();
        }}
      />

      <ConfirmationDialog
        open={showDeletionDialog}
        title="Folder successfully deleted!"
        illustrationSrc="/trash-illustration.svg"
        primaryBtnLabel="OK"
        primaryBtnOnClick={() => {
          setShowDeletionDialog(false);
        }}
      />
    </Box>
  );
};
