export const fullAuditData = async (documentName) => {
  return {
    templateName: 'Name1',
    documentName: 'Document2',
    templateVersion: 4,
    cognitoUser: 'kon1',
    actions: [
      {
        auditLines: [
          {
            auditId: 1,
            type: 'automated',
            date: '01/01/2022 17:23',
            note: 'document 873264 has been created.',
          },
          {
            auditId: 2,
            type: 'automated',
            date: '01/01/2022 17:23',
            note: 'document 873264 has been modified by cognitoUser Kon1, with cognitoId 27384628346. Lorem ipsum testing two lines',
          },
          {
            auditId: 3,
            type: 'manual',
            date: '01/01/2022 17:23',
            note: 'I have sent the document out to Mr White at IBM headquarters and there has been no immediate response.',
          },
        ],
        documentMetaData: {
          id: 1,
          date: '01/01/2022 17:23',
          emailSent: 'kon@kon.com',
          company: 'addmyask com',
          templateVersion: 3,
          NotesAtSent:
            'I am sending this document to company addmyask as it is needed by XYZ',
        },
      },
      {
        auditLines: [
          {
            auditId: 1,
            type: 'automated',
            date: '01/01/2022 17:23',
            note: 'document has been sent.',
          },
          {
            auditId: 2,
            type: 'automated',
            date: '01/01/2022 17:23',
            note: 'modified by cognitoUser Kon1, with cognitoId 27384628346.',
          },
          {
            auditId: 3,
            type: 'manual',
            date: '01/01/2022 17:23',
            note: 'sent the document to HR.',
          },
        ],
        documentMetaData: {
          id: 2,
          date: '01/01/2022 17:23',
          emailSent: 'marta@mail.com',
          company: 'Vouchcom',
          templateVersion: 2,
          NotesAtSent: 'Applying for new job @ Vouchcom',
        },
      },
    ],
  };
};
