export const IS_LOADING = 'IS_LOADING';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const GET_FOLDERS = 'GET_FOLDERS';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const GET_ALL_DOCUMENTS = 'GET_ALL_DOCUMENTS';
export const GET_ALL_DRAFT_DOCUMENTS = 'GET_ALL_DRAFT_DOCUMENTS';
export const GET_ALL_FAVOURITES_DOCUMENTS = 'GET_ALL_FAVOURITES_DOCUMENTS';
export const TOGGLE_FAVOURITE_DOCUMENT = 'TOGGLE_FAVOURITE_DOCUMENT';
export const GET_NON_DRAFT_DOCUMENTS_IN_FOLDERS =
  'GET_NON_DRAFT_DOCUMENTS_IN_FOLDERS';
