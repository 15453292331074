import { useReducer } from 'react';
import { fullAuditData } from '../../api/audit';
import AuditContext from './AuditContext';
import auditReducer from './AuditReducer';
import { GET_AUDIT } from './types';

export const initialState = {
  audit: {},
};

const AuditState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the templateReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(auditReducer, initialState);

  const getAuditData = (documentName) => {
    fullAuditData(documentName).then((result) => {
      // dispatch action to the reducer and update the state accordingly
      dispatch({
        type: GET_AUDIT,
        payload: result,
      });
    });
  };

  return (
    <AuditContext.Provider value={{ state: state || {}, getAuditData }}>
      {props.children}
    </AuditContext.Provider>
  );
};

export default AuditState;
