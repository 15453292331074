import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    placement="top-end"
    classes={{ popper: className }}
    arrow
  />
))(() => ({
  '&.MuiTooltip-popper': {
    width: '220px',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#494E57',
    fontSize: '14px',
    lineHeight: '20px',
    borderRadius: '12px',
    border: '2px solid #C4DB67',
    padding: '12px 20px',
    boxShadow: '0px 4px 16px 0px #222A023D',
  },
  '& .MuiTooltip-arrow': {
    color: 'white',
    zIndex: 2,
  },
}));

export const HtmlTooltip = StyledTooltip;
