import { useContext, useEffect, useState } from 'react';
import { Box, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import DocumentContext from 'context/document/DocumentContext';
import { InputField } from 'components/common/InputField';
import CoreLayout from 'components/layouts/CoreLayout';
import { SearchIcon } from 'components/common/icons/SearchIcon';
import { CustomButton } from 'components/common';
import { DocumentsList } from 'components/common/DocumentsList';
import { DropdownComponent } from 'components/common/DropdownComponent';

export const Drafts = () => {
  const {
    state: documentState,
    getAllDraftDocuments,
    createFolder,
  } = useContext(DocumentContext);

  // const [filter, setFilter] = useState({
  //   keyword: '',
  //   tag: '',
  //   date: '',
  // });

  const [filters, setFilters] = useState({
    keyword: '',
    folder: 'All Folders',
  });
  // const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [foldersList, setFoldersList] = useState([]);
  const [folderNamesList, setFolderNamesList] = useState([]);
  const [filteredFoldersData, setFilteredFoldersData] = useState([]);
  const [activeDocument, setActiveDocument] = useState(null);
  const [selectedFolderName, setSelectedFolderName] = useState('');
  const [showFolderNameForm, setShowFolderNameForm] = useState(false);

  useEffect(() => {
    getAllDraftDocuments();
  }, []);

  useEffect(() => {
    // check structure of received folders data and compare it with the existing one
    // setDocumentsList(documentState?.documents);

    const folders = documentState?.draftDocuments;
    // if (!Array.isArray(folders) || folders?.length === 0) return;
    const folderNames = folders?.map((folder) => folder.folderName);
    setFolderNamesList(['All Folders', ...folderNames]);
    setFoldersList(folders);
    setFilteredFoldersData(folders);
  }, [documentState?.draftDocuments]);

  // useEffect(() => {
  //   // setFilteredDocuments(documentsList);
  //   // ! TODO consider already active filter ...??
  // }, [documentsList]);

  // const applyKeywordFilter = (value, list) => {
  //   return list.filter((document) => {
  //     return document.documentName.toLowerCase().includes(value.toLowerCase());
  //   });
  // };

  // const applyTagFilter = (value, list) => {
  //   return list?.filter((document) => {
  //     return document.position.toLowerCase().includes(value.toLowerCase());
  //   });
  // };

  const handleFolderFilter = (folderName) => {
    setFilters({ ...filters, folder: folderName });
    if (folderName === 'All Folders') {
      setFilteredFoldersData(foldersList);
      return foldersList;
    }
    const filteredFolders = documentState?.folders?.filter(
      (f) => f.folderName === folderName
    );

    setFilteredFoldersData(filteredFolders);
    return filteredFolders;
  };

  const handleKeywordFilter = (keyword, data = documentState?.folders) => {
    setFilters({ ...filters, keyword });

    const filteredFolders = data.map((f) => {
      let documents = f.documents?.filter((d) =>
        d.documentName.toLowerCase().includes(keyword.toLowerCase())
      );
      return { ...f, documents };
    });
    setFilteredFoldersData(filteredFolders);
  };

  const handleFilterChanged = (type, value) => {
    const newFilters = { ...filters, [type]: value };
    setFilters(newFilters);

    if (newFilters?.folder === 'All Folders' && !newFilters?.keyword) {
      setFilteredFoldersData(foldersList);
      return;
    }

    if (newFilters?.folder !== 'All Folders' && !newFilters?.keyword) {
      handleFolderFilter(newFilters?.folder);
      return;
    }

    if (newFilters?.folder === 'All Folders' && newFilters?.keyword) {
      handleKeywordFilter(newFilters?.keyword);
      return;
    }

    const newFolderData = handleFolderFilter(newFilters?.folder);
    handleKeywordFilter(newFilters?.keyword, newFolderData);
  };

  // const applyDateFilter = (value, list) => {
  //   //
  // };

  // const resetFilter = (filterType) => {
  // let docs = deepCopy(documentsList);
  // if (filter?.keyword && filterType !== 'keyword') {
  //   docs = applyKeywordFilter(filter?.keyword, docs);
  // }
  // if (filter?.tag && filterType !== 'tag') {
  //   docs = applyKeywordFilter(filter?.tag, docs);
  // }
  // if (filter?.date && filterType !== 'date') {
  //   docs = applyKeywordFilter(filter?.date, docs);
  // }
  // setFilteredDocuments(docs);
  // };

  // const handleFolderFilterChange = (filterType, value) => {
  //   setFilter({ ...filter, [filterType]: value });
  //   if (!value) {
  //     resetFilter(filterType);
  //     return;
  //   }

  //   let updatedDocumentsList = deepCopy(filteredDocuments);

  //   switch (filterType) {
  //     case 'keyword':
  //       updatedDocumentsList = applyKeywordFilter(value, filteredDocuments);
  //       break;
  //     case 'tag':
  //       updatedDocumentsList = applyTagFilter(value, filteredDocuments);
  //       break;
  //     // case 'date':
  //     //   updatedDocumentsList = applyDateFilter(value, filteredDocuments);
  //     //   break;
  //   }

  //   setFilteredDocuments(updatedDocumentsList);
  //   //     setFilteredFoldersData(filteredDocuments);
  //   //     if (
  //   //       filteredDocuments.findIndex((folder) => folder.id === openedFolder?.id) ===
  //   //       -1
  //   //     ) {
  //   //       // if the currently opened folder is not a part of the filtered folders, then set first folder as opened
  //   //       setOpenedFolder(filteredDocuments[0]);
  //   //     }
  // };

  // const openFolderNameForm = (folderName = '') => {
  //   setShowFolderNameForm(true);
  //   if (folderName) {
  //     setSelectedFolderName(folderName);
  //   }
  // };

  const handleFolderCreation = () => {
    setShowFolderNameForm(false);
    createFolder(selectedFolderName);
    setSelectedFolderName('');
  };

  return (
    <CoreLayout pageTitle="Drafts">
      <Box
        sx={{ display: 'flex', flexDirection: 'row', mb: '40px', flexGrow: 1 }}
      >
        <Grid container sx={{ width: '100%' }} spacing="20px">
          <Grid
            item
            xs={6}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DropdownComponent
              name="filterByFolder"
              defaultValue={folderNamesList[0]}
              style={{
                width: '100%',
                mb: 2,
                mt: 0,
                display: 'block',
                borderRadius: '8px',
              }}
              options={folderNamesList}
              onChange={(_, value) => {
                handleFilterChanged('folder', value);
              }}
            />
            <InputField
              placeholder="Search by keyword"
              value={filters?.keyword}
              fullWidth={true}
              clearable
              onChange={(_, value) => handleFilterChanged('keyword', value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ height: '16px', width: '16px' }} />
                </InputAdornment>
              }
            />
            {/* <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  mb: '4px',
                  color: '#494E57',
                }}
              >
                Search by keyword:
              </Typography>
              <InputField
                name="foldersFilter"
                placeholder="Search"
                value={filter?.keyword}
                fullWidth={true}
                clearable
                onChange={(_, value) =>
                  handleFolderFilterChange('keyword', value)
                }
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ height: '16px', width: '16px' }} />
                  </InputAdornment>
                }
              />`` */}
          </Grid>
          {/* <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <CustomButton
              label="Create new folder"
              disabled={showFolderNameForm}
              variant="outlined"
              sx={{
                width: '180px',
                height: '48px',
                borderRadius: '12px',
                backgroundColor: '#6B8507',
                color: 'white',
                marginY: '6px',
              }}
              imgSrc={'/plus-icon.svg'}
              onClick={() => openFolderNameForm()}
            ></CustomButton>
          </Grid> */}
        </Grid>

        {/* <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
                color: '#494E57',
              }}
            >
              Search by tags:
            </Typography>
            <InputField
              name="foldersFilter"
              placeholder="Search"
              value={filter?.tag}
              fullWidth={true}
              clearable
              onChange={(_, value) => handleFolderFilterChange('tag', value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ height: '16px', width: '16px' }} />
                </InputAdornment>
              }
            />
          </Grid> */}
        {/* <Box sx={{ width: '200px', ml: '20px' }}>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
              mb: '4px',
              color: '#494E57',
            }}
          >
            Search by date:
          </Typography>
          <InputField
            name="foldersFilter"
            placeholder="Select date"
            value={filter?.date}
            fullWidth={true}
            clearable
            onChange={(_, value) => handleFolderFilterChange('date', value)}
            startAdornment={
              <InputAdornment position="start">
                <DatePickerIcon sx={{ height: '16px', width: '16px' }} />
              </InputAdornment>
            }
          />
        </Box> */}
      </Box>

      {showFolderNameForm ? (
        <Box
          sx={{
            my: '40px',
            display: 'flex',
            flexDirection: 'row',
            columnGap: '4px',
            position: 'relative',
          }}
        >
          <Box>
            <Typography
              sx={{
                color: '#494E57',
                fontSize: '14px',
                lineHeight: '20px',
                mb: '4px',
              }}
            >
              New Folder Name
            </Typography>
            <InputField
              placeholder="Folder 1"
              style={{
                width: '360px',
                '.MuiOutlinedInput-root': { pl: '12px' },
              }}
              value={selectedFolderName}
              fullWidth={true}
              clearable
              onChange={(_, value) => setSelectedFolderName(value)}
            />
            <Divider
              sx={{
                p: '1px 3px',
                borderColor: '#D7DAE0',
                position: 'absolute',
                width: '100%',
              }}
            />
          </Box>
          <Box sx={{ mt: 'auto', display: 'flex', columnGap: '4px' }}>
            <CustomButton
              style={{
                backgroundColor: '#F0F2F5',
                minWidth: 0,
                width: '36px',
                height: '36px',
                padding: '8px',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'no-wrap',
              }}
              imgSrc="/close-icon.svg"
              iconStyle={{
                height: '20px',
                width: '20px',
                display: 'block',
                margin: 'auto',
              }}
              onClick={() => {
                setShowFolderNameForm(false);
              }}
            />
            <CustomButton
              style={{
                backgroundColor: '#F2F7DF',
                minWidth: 0,
                width: '36px',
                height: '36px',
                padding: '8px',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'no-wrap',
              }}
              imgSrc="/tick-icon.svg"
              iconStyle={{
                height: '20px',
                width: '20px',
                display: 'block',
                margin: 'auto',
              }}
              onClick={handleFolderCreation}
            />
          </Box>
        </Box>
      ) : null}

      <DocumentsList
        foldersList={filteredFoldersData}
        activeDocument={activeDocument}
        setActiveDocument={setActiveDocument}
        shouldShowDivider={true}
        showDotsMenu={true}
        pageName="drafts"
        onFolderUpdate={getAllDraftDocuments}
      />
    </CoreLayout>
  );
};
