import React, { useState } from 'react';
import { Box } from '@mui/material';
import CoreLayout from 'components/layouts/CoreLayout';
import HistoryItem from '../common/HistoryItem';
import SearchFilterBar from '../common/SearchFilterBar';
import mockData from '../../api/exampleDocument.json';

export const History = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedType, setSelectedType] = useState('');

  const historyItems = mockData.auditLines.map((line) => ({
    date: new Date(line.date).toLocaleString(),
    type: line.type, // Fetch the raw type directly from mockData
    document: mockData.documentName,
    company: line.companyName,
    position: mockData.position,
    email: line.companyEmail,
    note: line.note,
    preview: null,
  }));

  // Handle Search Query
  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  // Handle Type Change
  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  // Filter Items
  const filteredItems = historyItems.filter((item) => {
    // Match search query (if provided)
    const matchesSearch =
      item.document.toLowerCase().includes(searchQuery) ||
      item.company.toLowerCase().includes(searchQuery) ||
      item.email.toLowerCase().includes(searchQuery) ||
      item.note.toLowerCase().includes(searchQuery) ||
      item.position.toLowerCase().includes(searchQuery);

    // Match selected type (if provided)
    const matchesType =
      selectedType === '' ||
      (selectedType === 'Note added' &&
        item.type.toLowerCase().includes('note')) ||
      (selectedType === 'Email sent' &&
        item.type.toLowerCase().includes('email')) ||
      (selectedType === 'Other' &&
        !item.type.toLowerCase().includes('note') &&
        !item.type.toLowerCase().includes('email'));

    return matchesSearch && matchesType;
  });

  return (
    <CoreLayout pageTitle="History">
      <Box sx={{}}>
        {/* Search Bar */}
        <Box sx={{ marginBottom: '20px' }}>
          <SearchFilterBar
            placeholder="Search"
            onSearch={handleSearch}
            onFilter={() => console.log('Filter clicked')}
            onTypeChange={handleTypeChange}
            selectedType={selectedType}
          />
        </Box>

        {/* Render History Items */}
        {filteredItems.map((item, index) => (
          <HistoryItem
            key={index}
            item={item} // Pass the entire item object including `type`
            isLast={index === filteredItems.length - 1}
          />
        ))}
      </Box>
    </CoreLayout>
  );
};

export default History;
