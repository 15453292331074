import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ThemeContext from 'context/theme/ThemeContext';
import TemplateContext from 'context/template/TemplateContext';
import DocumentContext from 'context/document/DocumentContext';
import { Alert, LoadingSpinner } from 'components/common';
import Sidebar from './Sidebar';
import UserContext from 'context/user/UserContext';

/* This is a component that needs to be used as a wrapper of pages that we want  
  to have NavBar on the page and container layout */

const CoreLayout = ({
  hideSidebar = false,
  pageTitle,
  backLinkLabel = 'Back to Templates',
  backLink,
  customPageWrapperLayout = false,
  wrapperStyle = null,
  children,
}) => {
  const { theme, closeAlert, withTheme } = useContext(ThemeContext);
  const { state: templateState } = useContext(TemplateContext);
  const { state: documentState } = useContext(DocumentContext);
  const { userState } = useContext(UserContext);
  const { shouldShowAlert, alertMessage } = theme || {};
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(
      templateState?.isLoading ||
        documentState?.isLoading ||
        userState?.isLoading
    );
  }, [
    templateState?.isLoading,
    documentState?.isLoading,
    userState?.isLoading,
  ]);

  return (
    <Grid item>
      {shouldShowAlert && (
        <Alert
          message={alertMessage}
          isOpen={shouldShowAlert}
          onClose={closeAlert}
        />
      )}
      <Sidebar
        hideSidebar={hideSidebar}
        pageTitle={pageTitle}
        backLink={backLink}
        backLinkLabel={backLinkLabel}
      >
        {showSpinner ? (
          <LoadingSpinner />
        ) : (
          <Grid
            item
            sx={{ width: '100%', ...wrapperStyle }}
            className={
              !customPageWrapperLayout ? withTheme('page-wrapper') : ''
            }
          >
            {children}
          </Grid>
        )}
      </Sidebar>
    </Grid>
  );
};

export default CoreLayout;
