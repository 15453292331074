import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import * as templateService from '../../api/template';
import TemplateContext from './TemplateContext';
import templateReducer from './TemplateReducer';
import {
  GET_TEMPLATE,
  GET_ALL_TEMPLATES,
  REMOVE_TEMPLATE,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  IS_LOADING,
} from './types';

export const initialState = {
  template: {},
  templates: [],
  isLoading: false,
};

const TemplateState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the templateReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(templateReducer, initialState);
  const navigate = useNavigate();

  const getAllTemplates = () => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    templateService
      .getAllTemplates()
      .then((result) => {
        // dispatch action to the reducer and update the state accordingly
        dispatch({
          type: GET_ALL_TEMPLATES,
          payload: result,
        });
      })
      .catch((error) => console.log(error))
      .finally(() =>
        dispatch({
          type: IS_LOADING,
          payload: false,
        })
      );
  };

  const getTemplateData = (templateId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    templateService
      .getTemplateById(templateId)
      .then((result) => {
        // dispatch action to the reducer and update the state accordingly
        dispatch({
          type: GET_TEMPLATE,
          payload: result,
        });
      })
      .catch((error) => console.log(error))
      .finally(() =>
        dispatch({
          type: IS_LOADING,
          payload: false,
        })
      );
  };

  const createTemplate = (data) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    templateService
      .createTemplate(data)
      .then(() => {
        // dispatch action to the reducer and update the state accordingly
        navigate('/templates');
        dispatch({
          type: CREATE_TEMPLATE,
        });
      })
      .catch((error) => console.log(error))
      .finally(() =>
        dispatch({
          type: IS_LOADING,
          payload: false,
        })
      );
  };

  const updateTemplate = (id, data) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    templateService
      .updateTemplate(id, data)
      .then(() => {
        // dispatch action to the reducer and update the state accordingly
        navigate('/templates');
        dispatch({
          type: UPDATE_TEMPLATE,
        });
      })
      .catch((error) => console.log(error))
      .finally(() =>
        dispatch({
          type: IS_LOADING,
          payload: false,
        })
      );
  };

  const removeTemplateById = (templateId) => {
    dispatch({
      type: IS_LOADING,
      payload: true,
    });
    templateService
      .removeTemplateById(templateId)
      .then((result) => {
        // dispatch action to the reducer and update the state accordingly
        dispatch({
          type: REMOVE_TEMPLATE,
          payload: result,
        });
      })
      .catch((error) => console.log(error))
      .finally(() =>
        dispatch({
          type: IS_LOADING,
          payload: false,
        })
      );
  };

  return (
    <TemplateContext.Provider
      value={{
        state: state || {},
        getTemplateData,
        getAllTemplates,
        createTemplate,
        updateTemplate,
        removeTemplateById,
      }}
    >
      {props.children}
    </TemplateContext.Provider>
  );
};

export default TemplateState;
