import React from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { DropdownComponent } from './DropdownComponent';
import { SearchIcon } from '../common/icons/SearchIcon'; // Adjust the import path as needed

const SearchFilterBar = ({
  placeholder = 'Search',
  onSearch,
  onFilter,
  onTypeChange,
  selectedType,
}) => {
  const dropdownOptions = [
    { id: '', label: 'All Types' },
    { id: 'Email sent', label: 'Email sent' },
    { id: 'Note added', label: 'Note added' },
    { id: 'Other', label: 'Other' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '100%',
        height: '36px',
      }}
    >
      {/* Search Input and Filter */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: '60%',
          height: '100%',
          gap: '12px',
        }}
      >
        {/* Input Field with Custom Search Icon */}
        <TextField
          variant="outlined"
          placeholder={placeholder}
          onChange={(e) => onSearch && onSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  style={{ color: '#A0A0A0', width: '16px', height: '16px' }}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            flex: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              height: '36px',
              fontWeight: '400',
              fontSize: '14px',
              color: '#707682',
              boxShadow: 'none',
            },
            '& .MuiOutlinedInput-input': {
              '&::placeholder': {
                fontWeight: '400',
                fontSize: '14px',
                color: '#6F7785',
              },
            },
          }}
        />

        <IconButton
          onClick={onFilter} // Filter button
          sx={{
            backgroundColor: '#F0F0F0',
            borderRadius: '8px',
            width: '36px',
            height: '36px',
            padding: '4px',
            boxSizing: 'border-box',
          }}
        >
          <img
            src="/filter-button.svg"
            alt="Filter"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </IconButton>
      </Box>

      {/* Types Dropdown and Sort Icon */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <DropdownComponent
          onChange={(name, newValue) =>
            onTypeChange && onTypeChange(newValue.id)
          }
          defaultValue={dropdownOptions.find(
            (option) => option.id === selectedType
          )}
          options={dropdownOptions}
          label=""
          name="typesFilter"
          size="small"
          style={{
            width: '228px',
            margin: '0',
            '.MuiOutlinedInput-root': {
              height: '36px',
              borderRadius: '8px',
              fontSize: '14px',
              color: '#494E57',
              backgroundColor: '#FFFFFF',
            },
          }}
        />

        {/* Sort History Icon */}
        <IconButton
          sx={{
            backgroundColor: '#F0F0F0',
            borderRadius: '8px',
            width: '36px',
            height: '36px',
            padding: '4px',
            boxSizing: 'border-box',
          }}
        >
          <img
            src="/sort-items-icon.svg"
            alt="Sort History"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SearchFilterBar;
