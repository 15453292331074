import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import {
  Stack,
  Drawer,
  Box,
  CssBaseline,
  Typography,
  AppBar,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import { CustomButton } from 'components/common';
import UserContext from 'context/user/UserContext';
import { getUserGroup } from 'helpers.js';
import { useMemo } from 'react';
import useDeviceType from 'hooks/useDeviceType';

const menuItems = [
  {
    label: 'CV Constructor',
    linkTo: '/templates',
    icon: '/puzzle-icon',
    activeIcon: '',
  },
  {
    label: 'My Story',
    linkTo: '/my-story',
    icon: '/user-square-regular',
    activeIcon: '',
  },
  {
    label: 'My CVs',
    linkTo: '/my-cvs',
    icon: '/note-regular',
    activeIcon: '',
  },
  {
    label: 'Drafts',
    linkTo: '/drafts',
    icon: '/pencil-simple-line',
    activeIcon: '',
  },
  {
    label: 'Favourites',
    linkTo: '/favourites',
    icon: '/star-regular',
    activeIcon: '',
  },
  {
    label: 'History',
    linkTo: '/history',
    icon: '/check-square-regular',
    activeIcon: '',
  },
  // {
  //   label: 'All Documents',
  //   linkTo: '/',
  //   icon: '/user-square-regular.svg',
  //   activeIcon: '',
  // },
  // {
  //   label: 'All Templates',
  //   linkTo: '/templates',
  //   icon: '/user-square-regular.svg',
  //   activeIcon: '',
  // },
];

const Sidebar = ({
  hideSidebar,
  pageTitle,
  backLink,
  backLinkLabel = 'Back to Templates',
  children,
}) => {
  const navigate = useNavigate();
  const [_1, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [_2, setIsUserAuthenticated] = useState(false);
  const { user, getUserInfo } = useContext(UserContext);

  const { isDesktop } = useDeviceType();

  const drawerWidth = useMemo(() => {
    return isDesktop ? 228 : 88;
  }, [isDesktop]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const name = useMemo(() => {
    return `${user?.given_name} ${user?.family_name}`;
  }, [user]);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    const userGroup = getUserGroup(user);
    setIsUserAuthenticated(userGroup !== 'notAuthorised');
  }, [user]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // not fixed
        ml: `${drawerWidth}px`, // not fixed
        backgroundColor: '#F7F7F5', // not fixed
      }}
    >
      <CssBaseline />
      {!hideSidebar ? (
        <>
          <AppBar
            position="relative" // not fixed
            sx={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              // ml: `${drawerWidth}px`, // fixed
            }}
          >
            <Box
              sx={{
                height: '48px',
                margin: '24px 60px 32px 60px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ color: '#494E57', lineHeight: '48px', fontSize: '32px' }}
              >
                {pageTitle}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Tooltip title="Open settings">
                  {/* <IconButton>
                    <Avatar
                      src="/notification-icon.svg"
                      sx={{ height: '24px', width: '24px', mr: '28px' }}
                    ></Avatar>
                  </IconButton> */}
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Avatar"
                      src="/avatar-icon.svg"
                      sx={{
                        height: '24px',
                        width: '24px',
                        mr: '8px',
                        pr: '0px',
                      }}
                    ></Avatar>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#292D33',
                      }}
                    >
                      {name}
                    </Typography>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {[{ label: 'Logout', value: '/logout' }]?.map(
                    (navLinkData, i) => (
                      <MenuItem key={i} onClick={handleCloseUserMenu}>
                        <Link to={navLinkData.value}>
                          <Button onClick={handleCloseNavMenu}>
                            {navLinkData.label}
                          </Button>
                        </Link>
                      </MenuItem>
                    )
                  )}
                </Menu>
              </Box>
            </Box>
          </AppBar>

          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
          >
            <Box
              sx={{
                overflow: 'auto',
                p: '24px 20px 36px 20px',
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Stack spacing={'20px'}>
                <Box>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ overflow: 'visible', ml: isDesktop ? 0 : '-5px' }}
                  >
                    <img
                      src={`/logo-${isDesktop ? 'full' : 'vertical'}.svg`}
                      alt="logo"
                    />
                  </Typography>
                </Box>

                {menuItems?.map((menuItem, index) => (
                  <NavLink
                    key={index}
                    className={({ isActive }) =>
                      isActive ? 'active-link nav-link' : 'nav-link'
                    }
                    to={`${menuItem.linkTo}`}
                  >
                    <img
                      src={`${menuItem.icon}${
                        menuItem.linkTo === window?.location?.pathname
                          ? '-filled.svg'
                          : '.svg'
                      }`}
                      style={{ width: '24px' }}
                      alt="menu-item"
                    />
                    {isDesktop ? (
                      <Typography
                        sx={{
                          ml: '12px',
                          fontSize: '14px',
                          lineHeight: '20px',
                        }}
                      >
                        {menuItem.label}
                      </Typography>
                    ) : null}
                  </NavLink>
                ))}
              </Stack>

              {/* <Box sx={{ p: '12px', height: '48px', display: 'flex' }}>
                <img
                  src="/gear-regular.svg"
                  alt="menu-item"
                  style={{ width: '24px' }}
                />
                {isDesktop ? (
                  <Typography
                    sx={{ ml: '12px', fontSize: '14px', lineHeight: '20, px' }}
                  >
                    Settings
                  </Typography>
                ) : null}
              </Box> */}
            </Box>
          </Drawer>
        </>
      ) : null}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: '#F7F7F5',
          //pt: 13, // not fixed
          minHeight: '100vh',
          px: '60px',
        }}
      >
        {backLink ? (
          <CustomButton
            variant="text"
            label={backLinkLabel}
            imgSrc="/backicon.svg"
            onClick={() => navigate(backLink)}
            style={{ marginRight: '12px', marginBottom: '12px' }}
          />
        ) : null}
        {/* <Box sx={{ color: '#707682', fontSize: '12px', lineHeight: '16px' }}>
          {subTitle}
        </Box> */}
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
