import { SET_THEME, SHOW_ALERT, CLOSE_ALERT } from './types';

/* 
  This is the Theme's Context reducer. It accepts action type (string) 
  and updates the store's state accordingly
*/

const ThemeReducer = (state, action) => {
  switch (action.type) {
    // map all action types to corresponding action (change of state)

    case SET_THEME: {
      const updatedState = {
        ...state,
        name: action.payload,
      };

      localStorage.setItem('theme', JSON.stringify(updatedState));

      // return the changed state
      return { ...updatedState };
    }

    case SHOW_ALERT: {
      return {
        ...state,
        shouldShowAlert: true,
        alertMessage: action.payload,
      };
    }

    case CLOSE_ALERT: {
      return {
        ...state,
        shouldShowAlert: false,
        alertMessage: null,
      };
    }

    // if no case is adequate to the action type, then the previous state is returned with no modifications done
    default:
      return state;
  }
};

export default ThemeReducer;
