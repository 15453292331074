import React, { useState } from 'react';
import { Box, Typography, Menu, MenuItem } from '@mui/material';
import { CustomButton } from './CustomButton'; // Adjust path accordingly

const HistoryItem = ({ item, isLast }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const handleSeeCVDetails = () => {
    handleCloseMenu();
    console.log('See CV details clicked');
  };

  const handleEditNote = () => {
    handleCloseMenu();
    console.log('Edit note clicked');
  };

  const handleDeleteNote = () => {
    handleCloseMenu();
    console.log('Delete note clicked');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: '16px',
        backgroundColor: '#FFFFFF',
        marginBottom: '12px',
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: isLast ? 'calc(50% - 16px)' : '-20px',
          left: '16px',
          width: '2px',
          backgroundColor: '#D7DAE0',
          zIndex: 0,
        },
      }}
    >
      {/* Icon Section */}
      <Box
        sx={{
          minWidth: '32px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        {item.type.toLowerCase().includes('note') ? (
          <img src="/note-added-history.svg" alt="Note Icon" />
        ) : (
          <img src="/email-sent-icon.svg" alt="Email Icon" />
        )}
      </Box>

      {/* Left Section: Image */}
      {/* {item.preview && ( */}
      <Box
        sx={{
          width: '80px',
          height: '100%',
        }}
      >
        <img
          src="/template4.png"
          alt="Document preview"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
      </Box>
      {/* )} */}

      {/* Right Section: Content */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {/* Date and Type */}
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '500',
            color: '#6B8507',
            lineHeight: '20px',
          }}
        >
          {item.date} - <span style={{ color: '#6A983C' }}>{item.type}</span>
        </Typography>

        {/* Document, Position, Company, Email, and Note */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '4px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Typography sx={{ fontSize: '12px', color: '#707682' }}>
              Document:
            </Typography>
            <Typography
              sx={{ fontSize: '12px', color: '#494E57', fontWeight: 400 }}
            >
              {item.document}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Typography sx={{ fontSize: '12px', color: '#707682' }}>
              Position:
            </Typography>
            <Typography
              sx={{ fontSize: '12px', color: '#494E57', fontWeight: 400 }}
            >
              {item.position}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Typography sx={{ fontSize: '12px', color: '#707682' }}>
              Company:
            </Typography>
            <Typography
              sx={{ fontSize: '12px', color: '#494E57', fontWeight: 400 }}
            >
              {item.company}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Typography sx={{ fontSize: '12px', color: '#707682' }}>
              Email:
            </Typography>
            <Typography
              sx={{ fontSize: '12px', color: '#494E57', fontWeight: 400 }}
            >
              {item.email}
            </Typography>
          </Box>

          <Box
            sx={{
              gridColumn: '1 / span 2',
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <Typography sx={{ fontSize: '12px', color: '#707682' }}>
              Note:
            </Typography>
            <Typography
              sx={{ fontSize: '12px', color: '#494E57', fontWeight: 400 }}
            >
              {item.note}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Dots Menu */}
      <Box
        sx={{
          marginLeft: 'auto', // Push the button to the far-right
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CustomButton
          style={{
            backgroundColor: 'transparent',
            minWidth: 0,
            width: '32px',
            height: '32px',
            padding: '0px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'no-wrap',
          }}
          imgSrc="/dots-menu-icon.svg"
          iconStyle={{
            height: 'auto',
          }}
          onClick={handleOpenMenu}
        />

        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={handleCloseMenu}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          PaperProps={{
            elevation: 0,
            sx: {
              boxShadow: '0px 8px 28px 0px rgba(34, 42, 2, 0.12)',
              borderRadius: '8px',
              mt: 1.5,
              ml: 3,
              width: '220px',
            },
          }}
        >
          <MenuItem onClick={handleSeeCVDetails}>See CV details</MenuItem>
          <MenuItem onClick={handleEditNote}>Edit note</MenuItem>
          <MenuItem onClick={handleDeleteNote}>Delete note</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default HistoryItem;
