import React, { useState } from 'react';
import { Box, Typography, Divider, Menu, MenuItem } from '@mui/material';
import { CustomButton } from './CustomButton'; // Adjust the import path if necessary

const HistoryCard = ({ date, type, email, company, note }) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  return (
    <Box
      sx={{
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        padding: '16px',
        display: 'flex',
        gap: '16px',
        alignItems: 'flex-start',
        position: 'relative',
        marginBottom: '8px',
      }}
    >
      {/* Left section: Date and type */}
      {(date || type) && (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', minWidth: '120px' }}
        >
          {date && (
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '400',
                color: '#494E57',
              }}
            >
              {date}
            </Typography>
          )}
          {type && (
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '400',
                color: '#494E57',
              }}
            >
              {type.replace(/\b\w/g, (char) => char.toUpperCase())}
            </Typography>
          )}
        </Box>
      )}

      {/* Vertical Divider (always visible for consistency) */}
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderColor: '#E0E0E0', marginLeft: '-40px' }}
      />

      {/* Right section: Details */}
      <Box sx={{ flexGrow: 1, maxWidth: '250px', wordBreak: 'break-word' }}>
        {type !== 'note' && email && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: '8px',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#707682',
                lineHeight: '16px',
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{ fontSize: '14px', lineHeight: '20px', color: '#494E57' }}
            >
              {email}
            </Typography>
          </Box>
        )}

        {type !== 'note' && company && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: '8px',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#707682',
                lineHeight: '16px',
              }}
            >
              Company
            </Typography>
            <Typography
              sx={{ fontSize: '14px', lineHeight: '20px', color: '#494E57' }}
            >
              {company}
            </Typography>
          </Box>
        )}

        {note && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#707682',
                lineHeight: '16px',
              }}
            >
              Note
            </Typography>
            <Typography
              sx={{ fontSize: '14px', lineHeight: '20px', color: '#494E57' }}
            >
              {note}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Dots Menu */}
      <Box
        sx={{
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CustomButton
          style={{
            backgroundColor: 'transparent',
            minWidth: 0,
            width: '24px',
            height: '24px',
            padding: '0px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'no-wrap',
          }}
          imgSrc="/dots-menu-icon.svg"
          iconStyle={{
            width: '24px',
            height: '24px',
          }}
          onClick={(event) => handleOpenMenu(event)}
        />
        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: '8px',
              mt: '8px',
              minWidth: '160px',
            },
          }}
        >
          <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
          <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default HistoryCard;
