import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import ThemeState from 'context/theme/ThemeState';
import UserState from 'context/user/UserState';
import TemplateState from 'context/template/TemplateState';
import DocumentState from 'context/document/DocumentState';
import AuditState from 'context/audit/AuditState';

import Login from 'components/pages/login';
import { Logout, NotFound404 } from 'components/common';

import './themes/default/pages/home.scss';
import './themes/default/common.scss';
import './themes/default/elements.scss';
import './themes/default/nav.scss';
import './themes/default/sections.scss';
import './themes/dark/pages/home.scss';
import './themes/dark/elements-dark.scss';
import './themes/dark/sections-dark.scss';
import './themes/dark/nav-dark.scss';
import './themes/blue/pages/home.scss';
import './themes/blue/elements-blue.scss';
import './themes/blue/sections-blue.scss';
import './themes/blue/nav-blue.scss';

import { AllTemplates } from 'components/pages/allTemplates';
import { ViewTemplate } from 'components/pages/viewTemplate';
import { CreateDocument } from 'components/pages/createDocument';
import { CVsList } from 'components/pages/cvsList';
import { TemplateOutlet } from 'components/pages/TemplateOutlet';
import { Drafts } from 'components/pages/drafts';
import { Favourites } from 'components/pages/favourites';
import { History } from 'components/pages/history';
import { MyStory } from 'components/pages/myStory';
import { ViewDocument } from 'components/pages/viewDocument';

const App = () => {
  return (
    <Router>
      <Box className="App">
        <ThemeState>
          <UserState>
            <DocumentState>
              <TemplateState>
                <AuditState>
                  {/* <Wrapper> */}
                  <Routes>
                    <Route exact path="/" element={<TemplateOutlet />}>
                      <Route exact path="/" element={<AllTemplates />} />
                      <Route
                        exact
                        path="/templates"
                        element={<AllTemplates />}
                      />
                      <Route
                        exact
                        path="/templates/:templateId"
                        element={<ViewTemplate />}
                      />
                    </Route>
                    <Route
                      exact
                      path="/createDocument/new/:templateId"
                      element={<CreateDocument mode={'new'} />}
                    />
                    <Route
                      exact
                      path="/createDocument/edit/:documentId"
                      element={<CreateDocument mode={'edit'} />}
                    />
                    <Route
                      exact
                      path="/createDocument/duplicate/:documentId/:templateId"
                      element={<CreateDocument mode={'duplicate'} />}
                    />

                    <Route
                      exact
                      path="/createDocument/copy/:documentId"
                      element={<CreateDocument mode={'copy'} />} //! change this with duplicate....
                    />

                    <Route exact path="/my-cvs" element={<CVsList />} />
                    <Route
                      exact
                      path="/my-cvs/:documentId"
                      element={<ViewDocument pageName="my-cvs" />}
                    />
                    <Route exact path="/my-story" element={<MyStory />} />
                    <Route exact path="/drafts" element={<Drafts />} />
                    <Route
                      exact
                      path="/drafts/:documentId"
                      element={<ViewDocument pageName="drafts" />}
                    />

                    <Route exact path="/favourites" element={<Favourites />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/history" element={<History />} />
                    <Route exact path="/logout" element={<Logout />} />
                    <Route path="*" element={<NotFound404 />} />
                  </Routes>
                </AuditState>
              </TemplateState>
            </DocumentState>
            {/* </Wrapper> */}
          </UserState>
        </ThemeState>
      </Box>
    </Router>
  );
};

export default App;
