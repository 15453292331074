/* Config data to connect to cognito */

let config = {
  cognitoAuthUrl: process.env.REACT_APP_COGNITO_AUTH_URL,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  logout_url: process.env.REACT_APP_LOGOUT_URL,
  // cognitoClientSecret: process.env.REACT_APP_COGNITO_CLIENT_SECRET,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
};

export default config;
