import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

export const DropdownComponent = ({
  onChange,
  defaultValue = null,
  options,
  label,
  name,
  size = 'small',
  style = {},
  disabled = false,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, []);

  return (
    <>
      <Autocomplete
        name={name}
        options={options}
        value={value}
        sx={{
          width: 300,
          mt: 2,
          height: '36px',
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
          },
          ...style,
        }}
        size={size}
        autoHighlight
        autoComplete
        disableClearable
        disabled={disabled}
        getOptionLabel={(option) =>
          option?.label?.toString() || option?.toString()
        }
        isOptionEqualToValue={(option, value) => {
          return option.id || option.id === ''
            ? option.id === value.id
            : option === value;
        }}
        onChange={(_, newValue) => {
          setValue(newValue);
          onChange(name, newValue);
        }}
        renderInput={(params) => (
          <TextField
            sx={{ borderRadius: '8px' }}
            {...params}
            name={name}
            label={label}
          />
        )}
        {...{ rest }}
      />
    </>
  );
};
