import { Box, Typography } from '@mui/material';
import { CustomButton } from 'components/common';

export const getSectionWrapperStyle = (
  selectedSection,
  selectedLineId,
  section,
  lineText,
  lineLocation,
  hideCloseButton = false
) => {
  return {
    flexGrow: 1,
    mr: '8px',
    padding: '8px 16px',
    height: section?.isTextArea ? '108px' : '36px',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '20px',
    color: lineText ? '#1F2126' : '#494E57',
    marginRight: hideCloseButton ? '44px' : '8px',
    backgroundColor: lineText ? '#F2F7DF' : '#F0F2F5',
    border:
      selectedSection?.sectionId === section?.sectionId &&
      selectedLineId === lineLocation
        ? '2px solid #88A612'
        : '',
    '&:hover': {
      cursor: 'pointer',
    },
  };
};

export const getEducationSectionContent = (
  section,
  addMarginTop,
  selectedSection,
  selectedLineId,
  handleLineSelection
) => {
  const { allocatedLines } = section;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: addMarginTop ? '20px' : '0px',
        }}
      >
        <Box
          sx={() =>
            getSectionWrapperStyle(
              selectedSection,
              selectedLineId,
              section,
              allocatedLines?.[0]?.lineText,
              allocatedLines?.[0]?.lineLocation
            )
          }
          onClick={() => {
            handleLineSelection(section, allocatedLines?.[0]?.lineLocation);
          }}
        >
          {allocatedLines?.[0]?.lineText || (
            <Typography
              sx={{
                p: 0,
                m: 0,
                color: '#707682',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            ></Typography>
          )}
        </Box>
        <CustomButton
          style={{
            backgroundColor: '#F0F2F5',
            minWidth: '36px',
            height: '36px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'no-wrap',
          }}
          imgSrc="/close-icon.svg"
          iconStyle={{
            height: '20px',
            width: '20px',
            display: 'block',
            margin: 'auto',
          }}
          onClick={
            () => {} // handleRemoveLine(section, allocatedLines[0]?.lineLocation)  // todo do this for subsection
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: addMarginTop ? '20px' : '0px',
        }}
      >
        <Box
          sx={() =>
            getSectionWrapperStyle(
              selectedSection,
              selectedLineId,
              section,
              allocatedLines?.[1]?.lineText,
              allocatedLines?.[1]?.lineLocation
            )
          }
          onClick={() => {
            handleLineSelection(section, allocatedLines?.[1]?.lineLocation);
          }}
        >
          {allocatedLines?.[1]?.lineText || (
            <Typography
              sx={{
                p: 0,
                m: 0,
                color: '#707682',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              Faculty, Graduation Level
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export const getExperienceSectionContent = (
  section,
  addMarginTop,
  selectedSection,
  selectedLineId,
  handleLineSelection,
  handleRemoveSubsection
) => {
  return section?.subSections?.map((subSection, sindex) => {
    return getExperienceSubSectionContent(
      section,
      subSection,
      addMarginTop,
      selectedSection,
      selectedLineId,
      handleLineSelection,
      handleRemoveSubsection
    );
  });
};

export const getExperienceSubSectionContent = (
  section,
  subSection,
  addMarginTop,
  selectedSection,
  selectedLineId,
  handleLineSelection,
  handleRemoveSubsection
) => {
  const { allocatedLines } = subSection;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: addMarginTop ? '20px' : '0px',
        }}
      >
        <Box
          sx={() =>
            getSectionWrapperStyle(
              selectedSection,
              selectedLineId,
              subSection,
              allocatedLines?.[0]?.lineText,
              allocatedLines?.[0]?.lineLocation
            )
          }
          onClick={() => {
            handleLineSelection(subSection, allocatedLines?.[0]?.lineLocation);
          }}
        >
          {allocatedLines?.[0]?.lineText || (
            <Typography
              sx={{
                p: 0,
                m: 0,
                color: '#707682',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              Company name
            </Typography>
          )}
        </Box>
        <CustomButton
          style={{
            backgroundColor: '#F0F2F5',
            minWidth: '36px',
            height: '36px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'no-wrap',
          }}
          imgSrc="/close-icon.svg"
          iconStyle={{
            height: '20px',
            width: '20px',
            display: 'block',
            margin: 'auto',
          }}
          onClick={() => handleRemoveSubsection(section, subSection?.['_id'])}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: addMarginTop ? '20px' : '0px',
        }}
      >
        <Box
          sx={() =>
            getSectionWrapperStyle(
              selectedSection,
              selectedLineId,
              subSection,
              allocatedLines?.[1]?.lineText,
              allocatedLines?.[1]?.lineLocation
            )
          }
          onClick={() => {
            handleLineSelection(subSection, allocatedLines?.[1]?.lineLocation);
          }}
        >
          {allocatedLines?.[0]?.lineText || (
            <Typography
              sx={{
                p: 0,
                m: 0,
                color: '#707682',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              Date From - Date To
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: addMarginTop ? '20px' : '0px',
        }}
      >
        <Box
          sx={() =>
            getSectionWrapperStyle(
              selectedSection,
              selectedLineId,
              subSection,
              allocatedLines?.[2]?.lineText,
              allocatedLines?.[2]?.lineLocation
            )
          }
          onClick={() => {
            handleLineSelection(subSection, allocatedLines?.[2]?.lineLocation);
          }}
        >
          {allocatedLines?.[2]?.lineText || (
            <Typography
              sx={{
                p: 0,
                m: 0,
                color: '#707682',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              Position
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: addMarginTop ? '20px' : '0px',
        }}
      >
        <img
          src="/list-item-icon.svg"
          style={{ marginLeft: '10px', marginRight: '10px' }}
          alt="list-item-icon"
        />
        <Box
          sx={() =>
            getSectionWrapperStyle(
              selectedSection,
              selectedLineId,
              subSection,
              allocatedLines?.[3]?.lineText,
              allocatedLines?.[3]?.lineLocation
            )
          }
          onClick={() => {
            handleLineSelection(subSection, allocatedLines?.[3]?.lineLocation);
          }}
        >
          {allocatedLines?.[3]?.lineText || (
            <Typography
              sx={{
                p: 0,
                m: 0,
                color: '#707682',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              Responsibility
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
