import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllTemplates = async () => {
  const response = await axios.get(`${BASE_URL}/template/all`);
  return response?.data || [];
};

export const getTemplateById = async (id) => {
  const response = await axios.get(`${BASE_URL}/template/one?id=${id}`);
  return response?.data || {};
};

export const createTemplate = async (data) => {
  await axios.post(`${BASE_URL}/template/create`, data);
};

export const updateTemplate = async (templateId, data) => {
  await axios.put(`${BASE_URL}/template/update?id=${templateId}`, data);
};

export const removeTemplateById = async (id) => {
  const response = await axios.delete(`${BASE_URL}/template/delete?id=${id}`);
  return response?.data || [];
};
