import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Cabin',
    h1: {
      fontSize: '26px',
      lineHeight: '40px',
      color: '#000000',
      fontWeight: 600,
    },
    h3: {
      fontSize: '20px',
      lineHeight: '24px',
      color: '#000000',
      fontWeight: 700,
    },
    h4: {
      fontSize: '18px',
      lineHeight: '24px',
      color: '#000000',
      fontWeight: 400,
    },
    h5: {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
      fontWeight: 700,
    },
    h6: {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
      fontWeight: 400,
    },
    pBold: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#000000',
      fontWeight: 700,
    },
    pMedium: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#000000',
      fontWeight: 500,
    },
    pRegular: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#000000',
      fontWeight: 400,
    },
    pRedularUnderlined: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#000000',
      fontWeight: 400,
      textDecoration: 'underline',
    },
    pSmall: {
      fontSize: '12px',
      lineHeight: '16px',
      color: '#000000',
      fontWeight: 400,
    },
  },
  palette: {
    grey: {
      90: '#1F2126',
      80: '#292D33',
      70: '#494E57',
      60: '#707682',
      50: '#99A0AD',
      40: '#B0B5BD',
      30: '#D7DAE0',
      20: '#F0F2F5',
      10: '#FABFBC',
      white: '#FFFFFF',
      main: 'grey',
    },
    primary: {
      90: '#425204',
      80: '#6B8507',
      70: '#88A612',
      60: '#B2C953',
      50: '#C4D667',
      basic: '#D5E881',
      40: '#E4F0B4',
      30: '#F2F7DF',
      20: '#F7F7F5',
      10: '#F7F7F5',
      redBasic: '#E36464',
      main: '#D5E881',
    },
    ai: {
      90: '#3D2552',
      80: '#633C85',
      70: '#7844A6',
      60: '#A57CB9',
      50: '#BA93DB',
      40: '#CFB0EB',
      30: '#E0CEF0',
      20: '#EFE6F7',
      10: '#F4F0F7',
      main: '#BA93DB',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
