import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FullscreenExit, Fullscreen, Close } from '@mui/icons-material';
import { CustomButton } from './CustomButton';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: '32px 40px 40px 40px',
    borderRadius: '12px',
  },
  '& .MuiDialog-paperFullWidth': {
    width: '100vw',
    maxWidth: '680px',
    maxHeight: '720px',
  },
  '& .MuiDialogContent-root': {
    padding: 0,

    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      paddingY: '8px',
      backgroundColor: '#F2F7DF', // background of the scrollbar handle
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C4DB67', // scrollbar handle color
      borderRadius: '8px',
    },
  },
}));

const CustomDialogTitle = (props) => {
  const {
    children,
    onClose,
    isFulscreen,
    showFullScreenIcon,
    toggleFullscreen,
    hasCloseIcon = false,
    ...other
  } = props;

  return (
    // This renders the title of the dialog together with needed icons
    // Possible icons:
    // - open and exit fullscreen (depends on showFullScreenIcon property)
    // - close modal (only shown if custom onClose function is sent from parent as prop)

    <DialogTitle
      sx={{
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#494E57',
        padding: 0,
        marginBottom: '40px',
      }}
      {...other}
    >
      {/* children property can contain the title or some custom element that 
        will be rendered inside the dialog title component */}
      {children}

      {/* if the value of the property showFullScreenIcon is true, 
        then a icon that toggles fullscreen is rendered */}
      {showFullScreenIcon ? (
        <IconButton
          onClick={toggleFullscreen}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: 'gray',
          }}
        >
          {isFulscreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
      ) : null}

      {/* if there is a custom method that is passed as a value of the prop onClose, 
        then a icon that closes the modal is rendered */}
      {onClose && hasCloseIcon ? (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'gray',
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const DialogWithForm = ({
  isOpen,
  toggle,
  actions = null,
  title,
  showFullScreenIcon = false,
  children,
}) => {
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <div>
      <CustomDialog onClose={toggle} open={isOpen} fullWidth={true}>
        {/* dialog title */}
        <CustomDialogTitle
          onClose={toggle}
          showFullScreenIcon={showFullScreenIcon}
          isFulscreen={fullscreen}
          toggleFullscreen={() => {
            setFullscreen((prev) => !prev);
          }}
        >
          {title}
        </CustomDialogTitle>

        {/* dialog content renders the data and elements 
            that are passed to this component as children */}
        <DialogContent>{children}</DialogContent>

        {/* if array of actions is sent then it renders a button for each of the defined actions */}
        {actions && actions.length > 0 ? (
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              mt: '60px',
            }}
          >
            {/* <CustomButton
              label="Back To Template"
              variant="outlined"
              onClick={toggle}
            /> */}
            <Box>
              {actions.map((action, index) => (
                <CustomButton
                  label={action.label}
                  autoFocus
                  key={index}
                  disabled={action.disabled}
                  onClick={action.onClick}
                  {...(action.imgSrc && { imgSrc: action.imgSrc })}
                  variant={action.variant || 'outlined'}
                  style={{ marginLeft: '8px', ...action.style }}
                />
              ))}
            </Box>
          </DialogActions>
        ) : null}
      </CustomDialog>
    </div>
  );
};
