import { SvgIcon } from '@mui/material';

export const SearchIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3538 13.6464L11.2244 10.5176C12.1314 9.42871 12.5837 8.03201 12.4871 6.6181C12.3906 5.20419 11.7527 3.88193 10.7061 2.92637C9.65948 1.97082 8.28478 1.45555 6.86794 1.48775C5.45111 1.51995 4.10123 2.09714 3.09911 3.09926C2.097 4.10137 1.51981 5.45125 1.48761 6.86808C1.45541 8.28492 1.97068 9.65963 2.92623 10.7062C3.88178 11.7528 5.20405 12.3908 6.61796 12.4873C8.03187 12.5838 9.42857 12.1315 10.5175 11.2245L13.6462 14.3539C13.6927 14.4003 13.7479 14.4372 13.8086 14.4623C13.8692 14.4875 13.9343 14.5004 14 14.5004C14.0657 14.5004 14.1308 14.4875 14.1914 14.4623C14.2521 14.4372 14.3073 14.4003 14.3538 14.3539C14.4002 14.3074 14.4371 14.2523 14.4622 14.1916C14.4873 14.1309 14.5003 14.0658 14.5003 14.0001C14.5003 13.9344 14.4873 13.8694 14.4622 13.8087C14.4371 13.748 14.4002 13.6928 14.3538 13.6464ZM2.5 7.00014C2.5 6.11013 2.76392 5.2401 3.25839 4.50008C3.75285 3.76006 4.45566 3.18328 5.27792 2.84268C6.10019 2.50209 7.00499 2.41298 7.87791 2.58661C8.75082 2.76024 9.55264 3.18883 10.182 3.81816C10.8113 4.4475 11.2399 5.24932 11.4135 6.12224C11.5872 6.99515 11.4981 7.89995 11.1575 8.72222C10.8169 9.54449 10.2401 10.2473 9.50007 10.7418C8.76004 11.2362 7.89002 11.5001 7 11.5001C5.80693 11.4988 4.66311 11.0243 3.81948 10.1807C2.97585 9.33704 2.50132 8.19321 2.5 7.00014Z"
          fill="#707682"
        />
      </svg>
    </SvgIcon>
  );
};
