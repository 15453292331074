import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Divider,
  InputAdornment,
  Chip,
} from '@mui/material';
import { CustomButton } from 'components/common';
import CoreLayout from 'components/layouts/CoreLayout';
import { InputField } from 'components/common/InputField';
import { useContext, useEffect, useMemo, useState } from 'react';
import DocumentContext from 'context/document/DocumentContext';
import { DialogWithForm } from 'components/common/DialogWithForm';
import {
  allocateEmptyLineInSection,
  initialiseSection,
  deepCopy,
  isSimpleSection,
  removeIdKeys,
  handleFolderFilter,
  handleKeywordFilter,
} from 'helpers';
import { SaveCVDialog } from 'components/common/SaveCVDialog';
import TemplateContext from 'context/template/TemplateContext';
import { ConfirmationDialog } from 'components/common/ConfirmationDialog';
import { DocumentsList } from 'components/common/DocumentsList';
import { SearchIcon } from 'components/common/icons/SearchIcon';
import { CustomizedDialog } from 'components/common/Dialog';
import { DropdownComponent } from 'components/common/DropdownComponent';
import {
  getEducationSectionContent,
  getExperienceSectionContent,
  getSectionWrapperStyle,
} from 'components/componentsHelpers/documentSectionsHelpers';

export const CreateDocument = ({ mode }) => {
  const navigate = useNavigate();
  const { documentId, templateId } = useParams();

  const { state: templateState, getTemplateData } = useContext(TemplateContext);
  const {
    state: documentState,
    getDocumentData,
    getFolders,
    createDocument,
    updateDocument,
    createFolderAndSaveDocument,
  } = useContext(DocumentContext);

  const [filters, setFilters] = useState({
    keyword: '',
    folder: 'All Folders',
  });

  const [userEntriesFilter, setUserEntriesFilter] = useState({
    linesFilter: '',
    tagsFilter: '',
  });

  const [_, setTags] = useState([]);
  const [documentData, setDocumentData] = useState(null);
  const [foldersList, setFoldersList] = useState([]);
  const [folderNamesList, setFolderNamesList] = useState([]);
  const [filteredFoldersData, setFilteredFoldersData] = useState([]);
  const [isFolderFormDirty, setIsFolderFormDirty] = useState(false);

  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedLineId, setSelectedLineId] = useState(null);

  const [activeDocument, setActiveDocument] = useState(null);
  const [lineToAllocate, setLineToAllocate] = useState(null);

  const [newTemplateLine, setNewTemplateLine] = useState({});
  const [filteredLinesData, setFilteredLinesData] = useState([]);

  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showChangeCVDataDialog, setShowChangeCVDataDialog] = useState(false);
  const [showGenerateCVDialog, setShowGenerateCVDialog] = useState(false);
  const [showChangeCVConfirmationDialog, setShowChangeCVConfirmationDialog] =
    useState(false);
  const [showSaveConfirmationDialog, setShowSaveConfirmationDialog] =
    useState(false);

  useEffect(() => {
    getFolders();
  }, []);

  useEffect(() => {
    if (['edit', 'copy', 'duplicate'].includes(mode)) {
      getDocumentData(documentId);
    } else {
      getTemplateData(templateId);
    }
  }, [documentId, templateId, mode]);

  // when creating/duplicating/copying document & when template is loaded/changed
  // if we are duplicating a document then we try to match sections by id
  // if we find matching sections, then we get the corresponding section data from the original document and copy it in the current document
  // then if the section does not have any allocated lines, we add empty line

  useEffect(() => {
    if (!templateState?.template || mode === 'edit') return;

    const { templateVersion, sections, _id } = templateState.template;

    const processSection = (section) => {
      let { allocatedLines, ...sectionData } = section;

      // Handle duplicate mode and find corresponding section in the document
      if (
        mode === 'duplicate' &&
        documentState?.document?.sections?.some(
          (s) => s.sectionId === section.sectionId
        )
      ) {
        const foundSection = documentState.document.sections.find(
          (s) => s.sectionId === section.sectionId
        );
        // !maybe we need to remove sth from the "foundSection" here
        sectionData = foundSection;
      }

      // Add appropriate lines or subsections based on the section style
      const isDuplicateWithNoLines =
        mode === 'duplicate' && !sectionData?.allocatedLines?.length;

      if (isDuplicateWithNoLines || mode === 'new') {
        if (isSimpleSection(section)) {
          sectionData = allocateEmptyLineInSection(section);
        } else if (
          section?.sectionName === 'Work Experience' &&
          section?.sectionStyle === 'subSections'
        ) {
          sectionData = addExperienceSubSectionToSection(section);
        } else if (
          section.sectionName === 'Education' &&
          section?.sectionStyle === 'subSections'
        ) {
          // Uncomment and define this method if needed
          // sectionData = addEducationSubSectionToSection(section);
        } else if (
          section.sectionStyle === 'subSections' &&
          !sectionData?.subSections?.length
        ) {
          sectionData = addSubsectionToSection(section);
        }
      }

      return sectionData;
    };

    const newSections = sections?.map(processSection);

    setDocumentData({
      templateVersion,
      sections: newSections,
      templateId: _id,
    });
  }, [templateState?.template]);

  useEffect(() => {
    const folders = documentState?.folders.filter(
      (folder) => folder.documents.length > 0
    );
    if (!Array.isArray(folders) || folders?.length === 0) return;

    const folderNames = folders?.map((folder) => folder.folderName);
    setFolderNamesList(['All Folders', ...folderNames]);
    setFoldersList(folders);
    setFilteredFoldersData(folders);
  }, [documentState?.folders]);

  useEffect(() => {
    if (!documentState?.document) return;

    if (mode === 'edit') {
      const updatedSections =
        documentState.document.sections.map(initialiseSection);

      setDocumentData({
        ...documentState.document,
        sections: updatedSections,
      });

      getTemplateData(documentState.document.templateId);
    } else if (mode === 'duplicate' && templateId) {
      getTemplateData(templateId);
    } else {
      setDocumentData(documentState.document);
    }
  }, [documentState?.document]);

  useEffect(() => {
    handleFilterChange();
  }, [selectedSection, documentData]);

  const shouldDisableGenerateButton = useMemo(() => {
    return documentData?.sections?.some(
      (section) =>
        section?.isMandatory &&
        (!section?.allocatedLines?.length ||
          section?.allocatedLines.every((line) => !line?.lineText))
    );
  }, [documentData]);

  const handleSubmit = () => {
    // generate doc
    // handle folder Name..
    // console.log('submitting', documentData);
  };

  const handleClose = () => {
    setFilters({ keyword: '', folder: 'All Folders' });
    setFilteredFoldersData(foldersList);
    setShowChangeCVDataDialog(false);
  };

  const handleFilterChanged = (type, value) => {
    const newFilters = { ...filters, [type]: value };
    setFilters(newFilters);

    if (newFilters?.folder === 'All Folders' && !newFilters?.keyword) {
      setFilteredFoldersData(foldersList);
      return;
    }

    if (newFilters?.folder !== 'All Folders' && !newFilters?.keyword) {
      handleFolderFilter(
        newFilters?.folder,
        filters,
        setFilters,
        foldersList,
        documentState?.folders,
        setFilteredFoldersData
      );
      return;
    }

    if (newFilters?.folder === 'All Folders' && newFilters?.keyword) {
      handleKeywordFilter(
        newFilters?.keyword,
        documentState?.folders,
        filters,
        setFilters,
        setFilteredFoldersData
      );
      return;
    }

    const newFolderData = handleFolderFilter(
      newFilters?.folder,
      filters,
      setFilters,
      foldersList,
      documentState?.folders,
      setFilteredFoldersData
    );
    handleKeywordFilter(
      newFilters?.keyword,
      newFolderData || documentState?.folders,
      filters,
      setFilters,
      setFilteredFoldersData
    );
  };

  const handleSubsectionRemoval = (section, subSectionId) => {
    const newDocumentData = deepCopy(documentData);
    newDocumentData.sections = newDocumentData?.sections?.map((s) => {
      if (s?.sectionId === section?.sectionId) {
        s.subSections = s.subSections.filter(
          (subSection) => subSection?._id !== subSectionId
        );
      }
      return s;
    });

    setDocumentData(newDocumentData);
  };

  const getSectionContent = (
    section,
    hideCloseButton = false,
    addMarginTop = false
  ) => {
    const arr = section?.allocatedLines || [{}];

    switch (section?.sectionStyle) {
      case 'Single Line Single Column':
      case 'Multiple Lines Single Column':
        if (arr.length === 1) {
          // hideCloseButton = true;
        }
        return arr.map((allocatedLine) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: addMarginTop ? '20px' : '0px',
            }}
            key={allocatedLine?.lineLocation}
          >
            {section?.isUnorderedList ? (
              <img
                src="/list-item-icon.svg"
                alt="list-item-icon"
                style={{ marginLeft: '10px', marginRight: '10px' }}
              />
            ) : null}
            <Box
              sx={getSectionWrapperStyle(
                selectedSection,
                selectedLineId,
                section,
                allocatedLine?.lineText,
                allocatedLine?.lineLocation,
                hideCloseButton
              )}
              onClick={() => {
                handleLineSelection(section, allocatedLine?.lineLocation);
              }}
            >
              {allocatedLine?.lineText || ''}
            </Box>
            {!hideCloseButton ? (
              <CustomButton
                style={{
                  backgroundColor: '#F0F2F5',
                  minWidth: '36px',
                  height: '36px',
                  padding: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'no-wrap',
                }}
                imgSrc="/close-icon.svg"
                iconStyle={{
                  height: '20px',
                  width: '20px',
                  display: 'block',
                  margin: 'auto',
                }}
                onClick={() =>
                  handleRemoveLine(section, allocatedLine?.lineLocation)
                }
              />
            ) : null}
          </Box>
        ));
      case 'Multiple Lines Dual Column':
        return section?.allocatedLines?.map((allocatedLine) => (
          <Box
            sx={{ display: 'flex', flexDirection: 'row' }}
            key={allocatedLine?.lineLocation}
          >
            <Box
              sx={getSectionWrapperStyle(
                selectedSection,
                selectedLineId,
                section,
                allocatedLine?.lineText,
                allocatedLine?.lineLocation,
                hideCloseButton
              )}
              onClick={() => {
                handleLineSelection(section, allocatedLine?.lineLocation);
              }}
            >
              {allocatedLine?.lineText || ''}
            </Box>
            <CustomButton
              style={{
                backgroundColor: '#F0F2F5',
                minWidth: '36px',
                height: '36px',
                padding: '8px',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'no-wrap',
              }}
              imgSrc="/close-icon.svg"
              iconStyle={{
                height: '20px',
                width: '20px',
                display: 'block',
                margin: 'auto',
              }}
              onClick={() =>
                handleRemoveLine(section, allocatedLine?.lineLocation)
              }
            />
          </Box>
        ));
      case 'subSections':
        if (!section?.subSections) return;

        if (section.sectionName === 'Work Experience') {
          return getExperienceSectionContent(
            section,
            addMarginTop,
            selectedSection,
            selectedLineId,
            handleLineSelection,
            handleSubsectionRemoval
          );
        } else if (section.sectionName === 'Education') {
          return getEducationSectionContent(
            section,
            addMarginTop,
            selectedSection,
            selectedLineId,
            handleLineSelection,
            handleSubsectionRemoval
          );
        }

        return section.subSections.map((subSection, sindex) => {
          return (
            <Box sx={{ mt: sindex === 0 ? '20px' : '8px' }}>
              {getSectionContent(subSection, sindex !== 0)}
            </Box>
          );
        });
      default:
        return <></>;
    }
  };

  const unselectLine = () => {
    setSelectedSection(null);
    setSelectedLineId(null);
    setNewTemplateLine({});
    setLineToAllocate(null);
  };

  const handleLineSelection = (section, lineLocation) => {
    if (
      section?.sectionId === selectedSection?.sectionId &&
      lineLocation === selectedLineId
    ) {
      unselectLine();
      return;
    }
    setSelectedSection(section);
    setSelectedLineId(lineLocation);
  };

  const shouldShowAddLineButton = (section) => {
    return (
      (section?.sectionStyle?.startsWith('Multiple Lines') &&
        section?.allocatedLines?.length < section?.maxLines) ||
      (section?.sectionStyle?.startsWith('Single Line') &&
        section?.allocatedLines?.length < 1)
    );
  };

  const handleRemoveLine = (section, lineLocation) => {
    const newDocumentData = deepCopy(documentData);
    newDocumentData?.sections?.forEach((s) => {
      if (s?.sectionId === section?.sectionId) {
        s.allocatedLines = s.allocatedLines.filter(
          (line) => line?.lineLocation !== lineLocation // TODO: improve this
        );
      }
    });
    setDocumentData(newDocumentData);
  };

  const addSubsectionToSection = (section) => {
    const subSection = section?.subSections[0];
    const { allocatedLines: subSectionAllocatedLines, ...subSectionData } =
      subSection;

    return {
      ...section,
      subSections: [...section.subSections, subSectionData],
    };
  };

  const addExperienceSubSectionToSection = (section = {}) => {
    // return updated section
    const subsectionsIds = section?.subSections?.map(
      (subSection) => subSection?.['_id']
    );
    const maxSubSectionId = subsectionsIds?.length
      ? Math.max(...subsectionsIds)
      : 0;

    const newSectionData = deepCopy(section);

    if (!newSectionData?.subSections) {
      newSectionData.subSections = [];
    }
    newSectionData.subSections.push({
      _id: maxSubSectionId + 1,
      templateLines: [],
      allocatedLines: [1, 2, 3, 4].map((i) => {
        return {
          lineLocation: i,
          lineText: '',
        };
      }),
    });

    return newSectionData;
  };

  const addEducationSubSectionToSection = (section = {}) => {
    // return updated section
    return section;
  };

  const handleAddSubsection = (section) => {
    if (!section) return;
    let newDocumentData = deepCopy(documentData);

    newDocumentData.sections = newDocumentData?.sections?.map((s) => {
      if (s?.sectionId === section?.sectionId) {
        if (
          section?.sectionStyle === 'subSections' &&
          section?.sectionName === 'Work Experience'
        ) {
          return addExperienceSubSectionToSection(section);
        } else if (
          section?.sectionName === 'Education' &&
          section?.sectionStyle === 'subSections'
        ) {
          return addEducationSubSectionToSection(section);
        } else {
          return addSubsectionToSection(s);
        }
      }
      return s;
    });

    setDocumentData(newDocumentData);
  };

  const handleAddLine = (section) => {
    let newDocumentData = deepCopy(documentData);
    newDocumentData.sections = newDocumentData?.sections?.map((s) => {
      if (s?.sectionId === section?.sectionId) {
        return allocateEmptyLineInSection(s);
      }
      return s;
    });

    setDocumentData(newDocumentData);
  };

  const handleAddTemplateLine = (shouldAllocate = false) => {
    let newDocumentSections = deepCopy(documentData);

    newDocumentSections = newDocumentSections?.sections?.map((section) => {
      if (section?.sectionId === selectedSection?.sectionId) {
        const newLineId = Math.floor(Math.random() * 10000000);
        if (newTemplateLine) {
          const lineToSave = {
            lineId: newLineId,
            lineVersion: 0,
            lineText: newTemplateLine.lineText || '',
            lineAdded: new Date().toISOString(),
            lineUpdated: new Date().toISOString(),
          };
          if (!section?.templateLines) {
            section.templateLines = [];
          }
          section?.templateLines.splice(0, 0, lineToSave);
          setSelectedSection(section);

          if (shouldAllocate) {
            const { lineVersion, lineText, lineId } = lineToSave;
            const updatedLine = {
              lineVersion,
              lineText,
              originalLineId: lineId,
              lineLocation: selectedLineId,
              lineAdded: new Date().toISOString(),
            };
            const selectedLineIndex =
              selectedSection?.allocatedLines?.findIndex(
                (line) => line.lineLocation === selectedLineId
              );
            section.allocatedLines[selectedLineIndex] = updatedLine;
            setSelectedSection(null);
            setSelectedLineId(null);
          }
        }
      }
      return section;
    });

    setNewTemplateLine({});
    setDocumentData({ ...documentData, sections: newDocumentSections });
    setTags([]);
  };

  const allocateLine = (line) => {
    const { lineVersion, lineText, lineId } = line;
    const updatedLine = {
      lineVersion,
      lineText,
      originalLineId: lineId,
      lineLocation: selectedLineId,
      lineAdded: new Date().toISOString(),
    };
    const selectedLineIndex = selectedSection?.allocatedLines?.findIndex(
      (line) => line.lineLocation === selectedLineId
    );

    let newDocumentSections = deepCopy(documentData);
    newDocumentSections = newDocumentSections?.sections?.map((section) => {
      if (section?.sectionId === selectedSection?.sectionId) {
        section.allocatedLines[selectedLineIndex] = updatedLine;
      }
      return section;
    });

    setDocumentData({ ...documentData, sections: newDocumentSections });
    setSelectedSection(null);
    setSelectedLineId(null);
  };

  const handleFilterChange = (value = '', filterType = '') => {
    if (
      !userEntriesFilter?.linesFilter &&
      !userEntriesFilter?.tagsFilter &&
      !value
    ) {
      setFilteredLinesData(selectedSection?.templateLines || []);
    }

    let newFilterData = userEntriesFilter;
    if (filterType) newFilterData = { ...newFilterData, [filterType]: value };

    setUserEntriesFilter(newFilterData);

    const filteredLines = selectedSection?.templateLines?.filter((line) => {
      return (
        line.lineText
          .toLowerCase()
          .includes(newFilterData?.linesFilter.toLowerCase()) &&
        (line.tags?.length === 0 ||
          line.tags.some((tag) =>
            tag.tagName
              .toLowerCase()
              .includes(newFilterData?.tagsFilter.toLowerCase())
          ))
      );
    });

    setFilteredLinesData(filteredLines);
  };

  const handleChangeDocumentMetadata = (key, value) => {
    setDocumentData({ ...documentData, [key]: value });
  };

  const onSaveAsDraft = async () => {
    let {
      __v,
      favorite,
      draft,
      email,
      folderName,
      templateName,
      actions,
      ...dataToSave
    } = deepCopy(documentData);
    removeIdKeys(dataToSave);

    const newSections = dataToSave?.sections?.map((section) => {
      let { allocatedLines, sectionNo, ...sectionData } = section;

      const newAllocatedLines = allocatedLines?.filter(
        (line) => line?.lineText
      );

      if (section?.sectionStyle !== 'subSections') {
        const { subSections, ...updatedSectionData } = section;
        return { ...updatedSectionData, allocatedLines: newAllocatedLines };
      }

      return { ...sectionData, allocatedLines: newAllocatedLines };
    });

    try {
      if (isFolderFormDirty) {
        await createFolderAndSaveDocument(
          folderName,
          {
            ...dataToSave,
            _id: documentId,
            sections: newSections,
            ...(mode !== 'edit' ? { draft: true } : {}),
          },
          mode === 'edit' ? 'edit' : 'create'
        );
      } else {
        if (mode === 'edit') {
          dataToSave.sections = newSections;
          dataToSave.tags = [];

          const { templateName, templateImageUrl, ...updatedData } = dataToSave;
          await updateDocument(documentId, updatedData);
        } else {
          await createDocument({
            ...dataToSave,
            sections: newSections,
            draft: true,
          });
        }
      }
      setShowSaveConfirmationDialog(true);
    } catch (err) {
      // TODO: handle error
      console.log('>> catch err', err);
    }
  };

  const handleSaveInput = () => {
    if (newTemplateLine?.lineText) {
      handleAddTemplateLine(true);
    } else {
      allocateLine(lineToAllocate);
    }
    setLineToAllocate(null);
  };

  return (
    <CoreLayout customPageWrapperLayout={true} pageTitle="CV Constructor">
      <Grid container sx={{ p: 0, mt: '8px' }} columnSpacing="60px">
        <Grid item xs={8}>
          <Stack
            spacing="32px"
            sx={{
              backgroundColor: 'white',
              borderRadius: '12px',
              p: '20px',
              maxWidth: '100%',
              height: '85vh',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                paddingY: '8px',
                backgroundColor: '#d7dae05e', // background of the scrollbar handle
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#D7DAE0', // scrollbar handle color
                borderRadius: '8px',
              },
            }}
          >
            {documentData?.sections?.map((section) => (
              <Box key={section?.sectionId}>
                <Stack spacing="8px">
                  <Typography
                    sx={{
                      fontWeight: '700',
                      lineHeight: '24px',
                      color: '#6B8507',
                      fontSize: '16px',
                    }}
                  >
                    {section?.sectionName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#707682',
                    }}
                  >
                    {section?.sectionDescription}
                  </Typography>
                  {getSectionContent(section)}

                  {/* <Grid
                    item
                    xs={12}
                    sx={{
                      borderRadius: '5px',
                      border: 'solid #dfd9d9 1px',
                      mb: 2,
                      p: 1,
                      mt: 1,
                    }}
                  > */}

                  {/* <CustomTagsComponent
                        tags={tags}
                        setTags={setTags}
                        renderTag={({ tag, deleteButtonText, onDelete }) => (
                          <TagComponent
                            tag={tag}
                            tagIndex={0}
                            tagColor={'grey'}
                            deleteButtonText={deleteButtonText}
                            onDelete={onDelete}
                          />
                        )}
                      /> */}

                  {['Work Experience', 'Education'].includes(
                    section?.sectionName
                  ) && section?.sectionStyle === 'subSections' ? (
                    <>
                      <CustomButton
                        label="Add Subsection"
                        variant="text"
                        imgSrc="/plus-icon.svg"
                        sx={{ width: '150px', color: '#292D33' }}
                        onClick={() => {
                          handleAddSubsection(section);
                        }}
                      />
                    </>
                  ) : shouldShowAddLineButton(section) ? (
                    <CustomButton
                      label="Add Line"
                      variant="text"
                      imgSrc="/plus-icon.svg"
                      sx={{ width: '150px', color: '#292D33' }}
                      onClick={() => {
                        handleAddLine(section);
                      }}
                    />
                  ) : null}
                </Stack>
              </Box>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          {!selectedLineId ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '12px',
                  alignItems: 'center',
                }}
              >
                <img
                  src={
                    templateState?.template?.templateImageUrl ||
                    'https://free-cv-templates.com/wp-content/uploads/2023/01/Perfect-CV-Free.jpg'
                  }
                  alt="template"
                  style={{
                    width: '48px',
                    height: '60px',
                    objectFit: 'cover',
                  }}
                />
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#707682',
                    }}
                  >
                    Template:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#494E57',
                    }}
                  >
                    {templateState?.template?.templateName}
                  </Typography>
                </Box>
              </Box>
              {mode === 'duplicate' ? (
                <Box
                  sx={{
                    mt: '12px',
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '12px',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={
                      templateState?.template?.templateImageUrl ||
                      'https://free-cv-templates.com/wp-content/uploads/2023/01/Perfect-CV-Free.jpg'
                    }
                    alt="template"
                    style={{
                      width: '48px',
                      height: '60px',
                      objectFit: 'cover',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#707682',
                      }}
                    >
                      Filled with CV data:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#494E57',
                      }}
                    >
                      {documentState?.document?.documentName}
                    </Typography>
                  </Box>
                  <Box>
                    <CustomButton
                      label="Change"
                      onClick={() => {
                        setShowChangeCVDataDialog(true);
                      }}
                      variant="text"
                      imgSrc="/arrow-clock-icon.svg"
                      style={{}}
                    />
                  </Box>
                </Box>
              ) : null}

              <Box sx={{ mt: '60px', mb: '500px' }}>
                <Typography
                  sx={{
                    color: '#707682',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                  }}
                >
                  Please select any section to fill it with your info
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CustomButton
                  label="Save to Drafts"
                  variant="outlined"
                  onClick={() => setShowSaveDialog(true)}
                  style={{ marginTop: '0px', marginRight: '12px' }}
                />
                <CustomButton
                  disabled={shouldDisableGenerateButton}
                  label="Generate my CV"
                  autoFocus
                  imgSrc="/note-regular.svg"
                  onClick={() => {
                    setShowGenerateCVDialog(true);
                    handleSubmit();
                  }}
                  variant="filled"
                  style={{ marginLeft: '8px' }}
                />
              </Box>
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  mb: 2,
                  p: 1,
                  mt: 1,
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      fontSize: '20px',
                      margin: 'auto',
                      color: '#292D33',
                      fontWeight: '700',
                    }}
                  >
                    {selectedSection?.sectionName}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#494E57',
                    mb: '4px',
                  }}
                >
                  Create new entry
                </Typography>
                <InputField
                  name="templateLine"
                  placeholder="Description"
                  value={newTemplateLine?.lineText || ''}
                  fullWidth
                  style={{ mb: 1, backgroundColor: 'white' }}
                  onChange={(_, value) => {
                    setNewTemplateLine({
                      lineText: value,
                      ...(newTemplateLine?.lineId && {
                        lineId: newTemplateLine?.lineId,
                      }),
                    });
                  }}
                />
                {/* <CustomTagsComponent
                  tags={tags}
                  setTags={setTags}
                  renderTag={({ tag, deleteButtonText, onDelete }) => (
                    <TagComponent
                      tag={tag}
                      tagIndex={0}
                      tagColor={'grey'}
                      deleteButtonText={deleteButtonText}
                      onDelete={onDelete}
                    />
                  )}
                /> */}

                <Divider
                  sx={{
                    borderColor: '#D7DAE0',
                    display: 'block',
                    marginBottom: '20px',
                  }}
                />

                {filteredLinesData?.length ? (
                  <Typography
                    sx={{
                      color: '#494E57',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '20px',
                    }}
                  >
                    Or fill with saved entries:
                  </Typography>
                ) : null}

                {/* <InputField
                  name="linesFilter"
                  label="Search lines"
                  value={userEntriesFilter.linesFilter}
                  clearable
                  onChange={(_, value) =>
                    handleFilterChange(value, 'linesFilter')
                  }
                />
                <InputField
                  name="tagsFilter"
                  label="Search tags"
                  value={userEntriesFilter.tagsFilter}
                  clearable
                  onChange={(_, value) =>
                    handleFilterChange(value, 'tagsFilter')
                  }
                /> */}
              </Grid>

              {filteredLinesData?.map((templateLine, templateLineIndex) => (
                <Grid
                  item
                  xs={12}
                  key={templateLineIndex}
                  sx={{
                    borderRadius: '8px',
                    mb: 1,
                    position: 'relative',
                    backgroundColor: 'white',
                    border: '#F0F2F5 1px solid',
                    ...(templateLine?.lineId === lineToAllocate?.lineId
                      ? {
                          border: '1px solid #88A612',
                        }
                      : ''),
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      padding: '12px',
                      borderRadius: '8px',
                      borderLeft: '8px solid #D5EB81',
                    }}
                  >
                    <Typography
                      sx={{ pr: 3, ':hover': { cursor: 'pointer' } }}
                      onClick={() => setLineToAllocate(templateLine)}
                    >
                      {templateLine?.lineText}
                    </Typography>
                    <Box>
                      {templateLine?.tags?.map((lineTag, tagIndex) => (
                        <Chip
                          key={tagIndex}
                          label={lineTag?.tagName}
                          variant="outlined"
                          size="small"
                          sx={{
                            mt: 1,
                            mr: 0.5,
                            borderColor: 'gray',
                            borderWidth: '2px',
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Grid>
              ))}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '20px',
                  justifyContent: 'space-between',
                }}
              >
                <CustomButton
                  label="Cancel"
                  variant="outlined"
                  onClick={unselectLine}
                  style={{ marginRight: '12px' }}
                />
                <CustomButton
                  label="Save Input"
                  disabled={
                    !newTemplateLine?.lineText && !lineToAllocate?.lineId
                  }
                  autoFocus
                  onClick={handleSaveInput}
                  variant="filled"
                  style={{ marginLeft: '8px', width: '180px' }}
                />
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={showChangeCVConfirmationDialog}
        title="Change CV data?"
        illustrationSrc="/question-illustration.svg"
        descriptionLines={[
          'With this action, you can lose all unsaved data you have added to this document',
          'Are you sure?',
        ]}
        primaryBtnLabel="Change CV Data"
        primaryBtnOnClick={() => {
          setShowChangeCVConfirmationDialog(false);
          setShowChangeCVDataDialog(false);
        }}
        secondaryBtnLabel="Cancel"
        secondaryBtnOnClick={() => {
          setShowChangeCVConfirmationDialog(false);
          setShowChangeCVDataDialog(false);
          // TODO: should open confirmation change cv data
        }}
      />

      <DialogWithForm
        isOpen={showSaveDialog}
        title="Save as a Draft"
        actions={[
          {
            label: 'Back to Constructor',
            imgSrc: '/backicon.svg',
            onClick: () => {
              setShowSaveDialog(false);
            },
            variant: 'outlined',
          },
          {
            label: 'Save as a Draft',
            imgSrc: '/pencil-simple-line.svg',
            onClick: () => {
              setShowSaveDialog(false);
              onSaveAsDraft();
            },
            disabled: false,
            variant: 'contained',
            style: { width: '180px', maxWidth: '100%' },
          },
        ]}
      >
        <SaveCVDialog
          foldersList={documentState?.folders}
          data={documentData}
          onChange={handleChangeDocumentMetadata}
          setIsFolderFormDirty={setIsFolderFormDirty}
          // handleFolderChanged={handleFolderChanged}
        />
      </DialogWithForm>
      <DialogWithForm
        isOpen={showGenerateCVDialog}
        title="Generate CV"
        actions={[
          {
            label: 'Back to Constructor',
            imgSrc: '/backicon.svg',
            onClick: () => {
              setShowGenerateCVDialog(false);
            },
            variant: 'outlined',
          },
          {
            label: 'Generate my CV',
            imgSrc: '/note-regular.svg',
            onClick: () => {},
            disabled: false,
            variant: 'contained',
            style: { width: '180px', maxWidth: '100%' },
          },
        ]}
      >
        <SaveCVDialog
          foldersList={documentState?.folders}
          showSubscriptionPlan={true}
          // handleFolderChanged={handleFolderChanged}
        />
      </DialogWithForm>

      <ConfirmationDialog
        open={showSaveConfirmationDialog}
        title="Draft successfully saved!"
        illustrationSrc="/tick-illustration.svg"
        primaryBtnLabel="OK"
        primaryBtnOnClick={() => {
          setShowSaveConfirmationDialog(false);
          navigate('/drafts');
        }}
      />

      <CustomizedDialog
        isOpen={showChangeCVDataDialog}
        title="Change CV data"
        toggle={handleClose}
        actions={[
          {
            label: 'Fill Manually',
            onClick: () => {
              navigate(`/createDocument/new/${templateId}`);
              setShowChangeCVDataDialog(false);
            },
            variant: 'outlined',
          },
          {
            label: 'Change CV Data',
            onClick: () => {
              navigate(
                `/createDocument/duplicate/${activeDocument?._id}/${templateId}`
                //, {  state: { documentId: activeDocument?.documentId } }
              );
              setShowChangeCVDataDialog(false);
            },
            disabled: !activeDocument,
            variant: 'contained',
            style: { width: '180px', maxWidth: '100%' },
          },
        ]}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
          <DropdownComponent
            name="filterByFolder"
            defaultValue={folderNamesList[0]}
            style={{
              width: '250px',
              maxWidth: '100%',
              mb: 2,
              mt: 0,
              display: 'block',
            }}
            options={folderNamesList}
            onChange={(_, value) => {
              handleFilterChanged('folder', value);
            }}
          />
          <InputField
            placeholder="Search by keyword"
            value={filters?.keyword}
            fullWidth={true}
            clearable
            onChange={(_, value) => handleFilterChanged('keyword', value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ height: '16px', width: '16px' }} />
              </InputAdornment>
            }
          />
        </Box>
        <DocumentsList
          foldersList={filteredFoldersData}
          activeDocument={activeDocument}
          setActiveDocument={setActiveDocument}
          shouldRedirect={false}
        />
      </CustomizedDialog>
    </CoreLayout>
  );
};
