import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getFoldersData = async () => {
  const response = await axios.get(`${BASE_URL}/folder/all`);
  return response?.data || [];
};

export const getNotDraftDocumentsGroupedByFolder = async () => {
  const response = await axios.get(`${BASE_URL}/folder/draft?draft=false`);
  return response?.data || [];
};

export const getDocuments = async () => {
  const response = await axios.get(`${BASE_URL}/document/all`);
  return response?.data || [];
};

export const getDraftDocuments = async () => {
  const response = await axios.get(`${BASE_URL}/document/draft`);
  return response?.data || [];
};

export const getFavouriteDocuments = async () => {
  const response = await axios.get(`${BASE_URL}/document/favorite`);
  return response?.data || [];
};

export const getDocumentById = async (documentId) => {
  const response = await axios.get(`${BASE_URL}/document/one?id=${documentId}`);
  return response?.data?.[0] || {};
};

export const createNewDocument = async (data) => {
  const response = await axios.post(`${BASE_URL}/document/create`, data);
  return response?.data || [];
};

export const createNewFolder = async (folderName) => {
  const response = await axios.post(`${BASE_URL}/folder/create`, {
    folderName,
  });
  return response?.data || [];
};

export const updateDocumentById = async (documentId, documentData) => {
  const response = await axios.put(
    `${BASE_URL}/document/update?id=${documentId}`,
    documentData
  );
  return response?.data || [];
};

export const updateFolderById = async (folderId, folderName) => {
  const response = await axios.put(`${BASE_URL}/folder/update?id=${folderId}`, {
    folderName,
  });
  return response?.data || [];
};

export const deleteDocumentById = async (documentId) => {
  const response = await axios.delete(
    `${BASE_URL}/document/delete?id=${documentId}`
  );
  return response?.data || [];
};

export const deleteFolderById = async (folderId) => {
  const response = await axios.delete(
    `${BASE_URL}/folder/delete?id=${folderId}`
  );
  return response?.data || [];
};

export const toggleFavouriteDocument = async (documentId) => {
  const response = await axios.put(
    `${BASE_URL}/document/toggleFavorite?id=${documentId}`
  );
  if (!response?.data) return [];

  return Array.isArray(response?.data) ? response.data[0] : response.data;
};
