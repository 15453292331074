import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { handleFolderFilter, handleKeywordFilter } from 'helpers';
import { CustomButton, TemplateCard } from 'components/common';
import CoreLayout from 'components/layouts/CoreLayout';
import TemplateContext from 'context/template/TemplateContext';
import { CustomizedDialog } from 'components/common/Dialog';
import DocumentContext from 'context/document/DocumentContext';
import { DocumentsList } from 'components/common/DocumentsList';
import { DropdownComponent } from 'components/common/DropdownComponent';
import { InputField } from 'components/common/InputField';
import { SearchIcon } from 'components/common/icons/SearchIcon';

export const ViewTemplate = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();

  const [activeTemplate, setActiveTemplate] = useState(null);
  const [similarTemplates, setSimilarTemplates] = useState([]);
  const [shouldShowDialog, setShouldShowDialog] = useState(false);
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [foldersList, setFoldersList] = useState([]);
  const [folderNamesList, setFolderNamesList] = useState([]);
  const [filteredFoldersData, setFilteredFoldersData] = useState([]);
  const [activeDocument, setActiveDocument] = useState(null);
  const [filters, setFilters] = useState({
    keyword: '',
    folder: 'All Folders',
  });
  const { state: templateState, getAllTemplates } = useContext(TemplateContext);
  const { state: documentState, getFolders } = useContext(DocumentContext);

  useEffect(() => {
    getAllTemplates();
    getFolders();
  }, [templateId]);

  useEffect(() => {
    const allTemplates = templateState?.templates;
    const activeTemplateData = allTemplates.find(
      (template) => template?.['_id'] === templateId
    );
    const similarTemplatesList = allTemplates
      .filter((template) => template?.['_id'] !== templateId)
      .slice(0, 3);

    setSimilarTemplates(similarTemplatesList);
    setActiveTemplate(activeTemplateData);

    if (activeTemplateData?.templateName === 'unavailable') {
      setIsUnavailable(true);
    } else {
      setIsUnavailable(false);
    }
  }, [templateState?.templates, templateId]);

  useEffect(() => {
    const folders = documentState?.folders.filter(
      (folder) => folder.documents.length > 0
    );
    if (!Array.isArray(folders) || folders?.length === 0) return;

    const folderNames = folders?.map((folder) => folder.folderName);
    setFolderNamesList(['All Folders', ...folderNames]);
    setFoldersList(folders);
    setFilteredFoldersData(folders);
  }, [documentState?.folders]);

  const showDialog = () => {
    setShouldShowDialog(true);
  };

  const handleClose = () => {
    setFilters({ keyword: '', folder: 'All Folders' });
    setFilteredFoldersData(foldersList);
    setShouldShowDialog(false);
  };

  const handleFilterChanged = (type, value) => {
    const newFilters = { ...filters, [type]: value };
    setFilters(newFilters);

    if (newFilters?.folder === 'All Folders' && !newFilters?.keyword) {
      setFilteredFoldersData(foldersList);
      return;
    }

    if (newFilters?.folder !== 'All Folders' && !newFilters?.keyword) {
      handleFolderFilter(
        newFilters?.folder,
        filters,
        setFilters,
        foldersList,
        documentState?.folders,
        setFilteredFoldersData
      );
      return;
    }

    if (newFilters?.folder === 'All Folders' && newFilters?.keyword) {
      handleKeywordFilter(
        newFilters?.keyword,
        documentState?.folders,
        filters,
        setFilters,
        setFilteredFoldersData
      );
      return;
    }

    const newFolderData = handleFolderFilter(
      newFilters?.folder,
      filters,
      setFilters,
      foldersList,
      documentState?.folders,
      setFilteredFoldersData
    );
    handleKeywordFilter(
      newFilters?.keyword,
      newFolderData,
      filters,
      setFilters,
      setFilteredFoldersData
    );
  };

  return (
    <CoreLayout
      customPageWrapperLayout={true}
      pageTitle="CV Constructor"
      backLink={'/templates'}
    >
      <Grid container sx={{ p: 0 }}>
        <Grid item xs={7}>
          <img
            src={activeTemplate?.templateImageUrl || '/viewTemplate1.png'}
            alt=""
            style={{
              maxWidth: '100%',
              marginLeft: '-25px',
              marginTop: '-5px',
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: '40px',
            }}
          >
            <Typography
              sx={{
                py: '6px',
                textSize: '20px',
                lineHeight: '24px',
                fontWeight: '700',
                color: '#494E57',
              }}
            >
              {activeTemplate?.templateName}
            </Typography>
          </Box>
          {similarTemplates?.length ? (
            <>
              <Typography
                sx={{
                  mb: '8px',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#707682',
                  fontWeight: '700',
                }}
              >
                Similar Templates
              </Typography>
              <Grid
                container
                gap="12px"
                sx={{
                  mt: 0,
                  justifyContent: 'flex-start',
                  backgroundColor: 'white',
                  borderRadius: '12px',
                  p: '12px',
                  mb: '40px',
                }}
              >
                {similarTemplates.map((i) => (
                  <TemplateCard
                    compactView={true}
                    template={i}
                    key={i?.['_id']}
                    sx={{
                      height: '100%',
                      borderRadius: '4px',
                    }}
                  />
                ))}
              </Grid>
            </>
          ) : null}
          {isUnavailable && (
            <Typography sx={{ mb: '40px', color: '#707682' }}>
              This Template is unavailable for your{' '}
              <Typography
                variant="span"
                sx={{ display: 'inline', fontWeight: '700', color: '#6B8507' }}
              >
                Pricing Plan
              </Typography>
              . Upgrade or select another one
            </Typography>
          )}

          {!isUnavailable ? (
            <CustomButton
              style={{ width: '100%' }}
              label="Use this Template"
              onClick={showDialog}
            />
          ) : null}
        </Grid>
      </Grid>

      <CustomizedDialog
        isOpen={shouldShowDialog}
        title="Fill with saved CV data"
        toggle={handleClose}
        actions={[
          {
            label: 'Fill Manually',
            onClick: () => {
              navigate(`/createDocument/new/${templateId}`);
            },
            variant: 'outlined',
          },
          {
            label: 'Fill with CV Data',
            onClick: () => {
              navigate(
                `/createDocument/duplicate/${activeDocument?._id}/${templateId}`
                //, {  state: { documentId: activeDocument?.documentId } }
              );
            },
            disabled: !activeDocument,
            variant: 'contained',
            style: { width: '180px', maxWidth: '100%' },
          },
        ]}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
            pt: '20px',
          }}
        >
          <DropdownComponent
            name="filterByFolder"
            defaultValue={folderNamesList[0]}
            style={{
              width: '250px',
              maxWidth: '100%',
              mb: 2,
              mt: 0,
              display: 'block',
            }}
            options={folderNamesList}
            onChange={(_, value) => {
              handleFilterChanged('folder', value);
            }}
          />
          <InputField
            placeholder="Search by keyword"
            value={filters?.keyword}
            fullWidth={true}
            clearable
            onChange={(_, value) => handleFilterChanged('keyword', value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ height: '16px', width: '16px' }} />
              </InputAdornment>
            }
          />
        </Box>
        <DocumentsList
          foldersList={filteredFoldersData}
          activeDocument={activeDocument}
          setActiveDocument={setActiveDocument}
          shouldRedirect={false}
        />
      </CustomizedDialog>
    </CoreLayout>
  );
};
